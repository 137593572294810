import React, { useEffect, useState } from "react";
import Layout from "../Layout";
import { Link } from "react-router-dom";
import { scrollFunction, scrollToTop, scrollToSection } from "../scroll.js";
import obsFlag from "../imgs/obs_flag.gif";
import browserSource from "../imgs/browser_source.gif";
import addCustom from "../imgs/add_custom.gif";
import elgato from "../imgs/elgato.svg";
import dock from "../imgs/dock.png";
import FAQStreamer from "../FAQ/tutorial.js"

function Streamers() {

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);
  const [showButton, setShowButton] = useState(false);


  useEffect(() => {
    const handleScroll = () => {
      setShowButton(scrollFunction());
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div className="leave-top_margin">
      <Layout>
        {showButton && (
          <button id="myBtn" onClick={scrollToTop} style={{ display: "block" }}>
            <svg
              style={{ color: "#7cd959" }}
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              fill="currentColor"
              className="bi bi-arrow-up-circle-fill"
              viewBox="0 0 16 16"
            >
              <path
                d="M16 8A8 8 0 1 0 0 8a8 8 0 0 0 16 0zm-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z"
                fill="#4fe83b"
              ></path>
            </svg>
          </button>
        )}
        <div className="streamer_header">
          <h1>
            Getting Started with <b className="highlighted">Streamfog</b>
          </h1>
        </div>
        <div className="streamer_body">
          <div className="table_contents">
            <h2 className="highlighted">Table of Contents</h2>
            <div className="table-of-contents__item">
              <Link to="#" onClick={() => scrollToSection("how")}>
                1. Introduction
              </Link>
            </div>
            <div className="table-of-contents__item">
              <Link to="#" onClick={() => scrollToSection("setup")}>
                2. Setup
              </Link>
            </div>
            <ol className="setup_list">
              <li>
                {" "}
                <Link to="#" onClick={() => scrollToSection("obs")}>
                  Preparing OBS
                </Link>
              </li>
              <li>
                <Link to="#" onClick={() => scrollToSection("browser")}>
                  Adding Streamfog Browser Source to OBS
                </Link>
              </li>
              <li>
                <Link to="#" onClick={() => scrollToSection("alerts")}>
                  Adding and Managing Alerts
                </Link>
              </li>
            </ol>
            <div className="table-of-contents__item">
              <Link to="#;" onClick={() => scrollToSection("advanced_features")}>
                3. Advanced Features
              </Link>
            </div>
            <ol className="setup_list">
              <li>
                {" "}
                <Link to="#" onClick={() => scrollToSection("spotlight")}>
                  Spotlight
                </Link>
              </li>
              <li>
                {" "}
                <Link to="#" onClick={() => scrollToSection("cam_calib")}>
                  Camera Calibration
                </Link>
              </li>
              <li>
                <Link to="#" onClick={() => scrollToSection("obs_dock")}>
                  OBS Streamfog Dock
                </Link>
              </li>
              <li>
                <Link to="#" onClick={() => scrollToSection("streamdeck")}>
                  Streamdeck Plugin
                </Link>
              </li>
            </ol>
            <div className="table-of-contents__item">
              <Link to="#" onClick={() => scrollToSection("faq")}>
                4. FAQ & Troubleshooting
              </Link>
            </div>
          </div>
          <div className="table_body">
            <div className="table_html">
              <div id="how">
                <h1 className="highlighted">
                  Introduction
                </h1>
                <p>
                  Streamfog is an app that allows creators to use AR in their stream in a very simple and lightweight way.
                  The setup is similar to Streamelements for example. You have your settings on a website and simply add a
                  browser source to OBS. This browser source will show your camera including the AR effects and replace your
                  camera in your OBS scenes. That's it. Let's go through it step by step!
                </p>
              </div>
              <div id="setup">
                <h1 className="highlighted">Preparing OBS (one time only)</h1>
                <p>
                  Before we go into the actual setup we have to configure OBS correctly. This step has to be only done once.
                  We are gonna add a parameter to the OBS shortcut. This is needed for the browser source to be able to access your camera.
                  When a website asks for camera permission in your regular Browser (Chrome, Edge, Firefox, etc.), you are asked if the website
                  is allowed to use your camera. OBS cannot ask you for permission, so it denies it by default. The parameter fixes that.
                </p>
                <ol>
                  <li>
                    <b className="highlighted">
                      Right-click on your OBS shortcut and select
                      'Properties'.
                    </b>
                  </li>
                  <li>
                    <b className="highlighted">
                      In the 'Target' field, add{" "}
                      <span
                        style={{
                          fontFamily: "monospace",
                          color: "red",
                          margin: 2,
                        }}
                      >
                        --use-fake-ui-for-media-stream
                      </span>
                      at the end of the line (add a space between).
                    </b>
                  </li>
                  <li>
                    <b className="highlighted">
                      Click 'Apply' and then 'OK.'
                    </b>
                  </li>
                  <li>
                    <b className="highlighted">Restart OBS</b>
                  </li>
                  <br />
                  <img
                    src={obsFlag}
                    alt="Placeholder for gif showing how to do this"
                  />
                </ol>
                <p>
                  Keep in Mind that if you open your OBS by searching for it in the windows search, you can add the parameter as easy:
                </p>
                <ol>
                  <li>
                    <b className="highlighted">
                      Search for OBS in your Windows Search Bar.
                    </b>
                  </li>
                  <li>
                    <b className="highlighted">
                      Right-click on OBS.
                    </b>
                  </li>
                  <li>
                    <b className="highlighted">
                      Click Open file location.
                    </b>
                  </li>
                  <li>
                    <b className="highlighted">
                      Follow the same steps as above
                    </b>
                  </li>
                </ol>
                <p>
                  After completing the steps, you can open your OBS the same way you did before. Just make sure you open the correct shortcut!
                </p>

                <h2 id="browser">
                  Adding{" "}
                  <b className="highlighted">Streamfog Browser Source</b> to
                  OBS
                </h2>
                <p>
                  After you've added the OBS flag, follow these simple steps
                  to add your personalized camera URL to OBS:
                </p>
                <ol>
                  <li>
                    <b className="highlighted">
                      Copy the personalized camera URL:
                    </b>{" "}
                    This URL is unique to you, so it's important to keep it to
                    yourself.
                  </li>
                  <li>
                    <b className="highlighted">
                      Create a new Browser Source:
                    </b>
                    <ul>
                      <li>Paste the Camera URL.</li>
                      <li>
                        For width and height, choose your selected camera
                        resolution.
                      </li>
                      <li>
                        Check "custom fps" and change the FPS to 60
                        (Optional).
                      </li>
                      <li>
                        In page permission, select "Advanced Access to OBS" if
                        you want to use the spotlight feature. (Optional)
                      </li>
                    </ul>
                  </li>
                  <li>
                    <b className="highlighted">Done:</b> Your browser should
                    now show your camera.
                  </li>
                  <br />
                  <img
                    src={browserSource}
                    alt="Placeholder for gif showing how to do this"
                  />
                </ol>
                <p>
                  You can now use this browser source and it will act like
                  your Webcam source. Make sure to duplicate it between scenes
                  instead of creating new ones. If you have any problems,
                  refer to the Troubleshooting section or contact our support
                  team.
                </p>

                <h2 id="alerts">
                  Adding and Managing{" "}
                  <b className="highlighted">Alerts: Your Guide</b>
                </h2>
                <p>
                  With Streamfog, you can easily add, manage, and play
                  exciting alerts that elevate your streaming experience.
                  Whether you're looking to access our inbuilt marketplace or
                  upload custom alerts, we've got you covered. Here's how:
                </p>

                <h3 className="highlighted">Your Dashboard</h3>
                <p>
                  Your Streamfog dashboard is your command center. Here,
                  you'll find all the alerts you've added to your library.
                  These can be alerts from our marketplace or custom ones
                  you've uploaded. Navigating your dashboard is simple and
                  intuitive. (Placeholder for gif showing the dashboard)
                </p>

                <h3 className="highlighted">Exploring the 3D Overlays</h3>
                <p>
                  Our inbuilt marketplace is home to an array of pre-designed
                  alerts ready to be added to your library. Browse through
                  different categories, themes, and styles to find the perfect
                  match for your stream. Adding alerts from the marketplace is
                  just a click away.
                </p>

                <h3 className="highlighted">Uploading Custom Alerts</h3>
                <p>
                  If you have a custom alert you'd like to use, the uploading
                  process is seamless. You can upload your custom alert on the
                  marketplace page. If you have questions about the format and
                  requirements for a custom overlay, please refer to our
                  detailed guide{" "}
                  <Link to="/artists">here</Link>.
                </p>

                <h3 className="highlighted">Where Do I Get Custom Alerts?</h3>
                <p>
                  Custom alerts are a fantastic way to create a unique and
                  personalized streaming experience, and with Streamfog,
                  getting them is easy. We're proud to offer custom alerts
                  designed by our experienced team of 3D artists—the OG's of
                  AR alerts for Twitch. Since no one has done it before, our
                  team's expertise is unparalleled in creating engaging and
                  interactive AR alerts tailored to your needs.
                </p>
                <p>
                  You can purchase custom alerts directly from us by either
                  scheduling a meeting or filling out a form within our app.
                  Our user-friendly interface guides you through the process,
                  making it simple to bring your creative vision to life.
                  (Placeholder for gif showing how to schedule a meeting or
                  fill out the form)
                </p>
                <p>
                  If you prefer to design your custom alerts or work with an
                  external artist, you can still upload them to your library,
                  following the guidelines found{" "}
                  <Link to="/artists">here</Link>.
                </p>
                <br />
                <img
                  src={addCustom}
                  alt="Placeholder for gif showing how to do this"
                />

              </div>
            </div>
            <div id="advanced_features">
              <h1 className="highlighted">Advanced Features</h1>
              <ul>
                <li>
                  <h2 id="streamdeck">Streamdeck Plugin</h2>
                  <p>
                    Simply copy your remote key from your settings into the
                    Plugin and you are good to go.
                  </p>
                  <Link
                    to="https://apps.elgato.com/plugins/com.streamfog.main"
                    target="_blank"
                    style={{ textDecoration: "none" }}
                  >
                    <div
                      style={{
                        border: "2px solid #7cd959",
                        borderRadius: 10,
                        display: "inline-flex",
                        alignItems: "center",
                        padding: 10,
                        cursor: "pointer",
                      }}
                    >
                      <img
                        src={elgato}
                        alt="Download the Plugin"
                        style={{ width: 50 }}
                      />
                      <span
                        style={{
                          marginLeft: 10,
                          color: "#7cd959",
                          fontWeight: "bold",
                        }}
                      >
                        Get the Plugin
                      </span>
                    </div>
                  </Link>
                  <br />
                  <br />
                </li>
                <li>
                  <h2 id="spotlight">
                    Spotlight: Emphasizing your Cam during Alerts
                  </h2>
                  <p>
                    Streamfog's spotlight feature is designed to give you the
                    ability to emphasize the 3D alerts that interact with your
                    room, even when the webcam view in your OBS scene is not the
                    main focus. This is particularly useful for streamers who
                    prioritize gameplay footage but still want to enhance their
                    stream with immersive alerts.
                  </p>
                  <h4 className="highlighted">How does it work?</h4>
                  <p>
                    The spotlight feature allows you to create a momentary focus
                    on the 3D alerts by setting an origin scene (your regular
                    gameplay scene) and a target scene (an enlarged camera
                    view). When a 3D alert is triggered, your OBS scene will
                    automatically switch to the target scene, and then return to
                    the original gameplay scene once the alert has played.
                  </p>
                </li>
                <li>
                  <h2 id="cam_calib">
                    Cam Reference Image: Consistency for Your 3D Alerts
                  </h2>
                  <p>
                    When using Streamfog's immersive 3D alerts that interact
                    with your room, consistency is key. Your alerts are designed
                    to work with a specific camera angle and room layout, and
                    any accidental shift can disrupt the 3D illusion. That's why
                    we've introduced the camera reference image feature.
                  </p>
                  <h4 className="highlighted">What it does</h4>
                  <p>
                    This feature allows you to upload a reference image of your
                    camera's view - the same one you might have sent to an
                    artist when creating your custom alert. If your camera angle
                    changes accidentally, you can use this image to recalibrate
                    your setup, ensuring that the 3D alerts continue to work
                    seamlessly. Upload it in your Settings, toggle it on an off
                    from your dashboard.
                  </p>
                </li>
                <li>
                  <h2 id="obs_dock">OBS Streamfog Dock</h2>
                  <p>
                    Streamfog's Dashboard can be easily integrated into OBS as a
                    browser dock that allows streamers to manage and trigger
                    their 3D alerts directly within OBS. This functionality
                    provides an efficient way to handle your 3D alerts without
                    the need to navigate away from your main streaming
                    interface. <br />
                    Simply go to Docks -{">"} Custom Browser Docks and add
                    "app.streamfog.com/dashboard".
                  </p>
                  <div>
                    <img
                      src={dock}
                      alt="Screenshot showing how to integrate Streamfog's Dashboard into OBS"
                      style={{ height: 500, width: "auto" }}
                    />
                  </div>
                </li>
              </ul>
            </div>
            <div id="faq" className="desktop-faqs">
              <h1 className="highlighted" style={{ marginTop:"20%", textAlign:"center"}}>FAQ </h1>
              <FAQStreamer />
            </div>
          </div>

        </div>
      </Layout>
    </div>
  );
}

export default Streamers;

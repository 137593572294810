import React from 'react';
import { Link } from "react-router-dom";
import { Grid, Box, Typography } from '@mui/material';
import book from '../imgs/book-open.svg';
import coin from '../imgs/dashicons_money-alt.png';
import snapcamera from '../imgs/ic_baseline-snapchat.png';
import carbon from '../imgs/carbon_area-custom.png';
import obs from '../imgs/simple-icons_obsstudio.png';
import lucide from '../imgs/lucide_network.png';
import bl from "../imgs/bottom_left_faq.webp";
import br from "../imgs/bottom_right_faq.webp";
import bm from "../imgs/bottom_middle_faq.webp";

const faqData = [
  {
    imgSrc: book,
    alt: 'book',
    title: 'How Can I get started?',
    description: 'The easiest way is to watch our YouTube ',
    linkText: 'tutorial',
    linkHref: '/streamers'
  },
  {
    imgSrc: coin,
    alt: 'coin',
    title: 'Is Streamfog free?',
    description: 'Yes, streamfog is free to use. Forever. We are currently only charging for custom assets.'
  },
  {
    imgSrc: snapcamera,
    alt: 'snapcamera',
    title: 'What is the difference to SnapCamera?',
    description: 'SnapCamera was a byproduct of SnapChat that showed the potential and also the popularity for AR in Streaming. Unfortunately, it was never maintained or tailored to be a tool for streamers. We created streamfog for streamers. Similar technology, but better integration with OBS and Twitch.'
  },
  {
    imgSrc: carbon,
    alt: 'carbon',
    title: 'Can I use custom assets?',
    description: 'Yes, and you should if you want the animations to interact with your room. As a 3D-Artist, you can create your own assets and import them. You can also find artists on platforms like fiver. We describe how assets can be created ',
    linkText: 'here',
    linkHref: '/artists',
    extraDescription: 'We are also offering the creation of custom assets. You can get a quote ',
    extraLinkText: 'here',
    extraLinkHref: '/artists',
    hasImage: true,
    imgSrcExtra: bl
  },
  {
    imgSrc: obs,
    alt: 'obs',
    title: 'Why do I need to change my OBS Shortcut?',
    description: 'The browser source needs camera access, but OBS cannot ask you for permission. The flag fixes that.',
    hasImage: true,
    imgSrcExtra: bm
  },
  {
    imgSrc: lucide,
    alt: 'lucide',
    title: 'Am I sending my camera to some server?',
    description: 'No! Your camera stream never leaves your PC. In fact, if you disconnect from the Internet, you can observe that the camera is still visible. Everything happens locally, no one can see your camera - unless you start streaming.',
    hasImage: true,
    imgSrcExtra: br
  }
];

const FAQSection = () => {
  return (
    <Grid container spacing={2}>
      {faqData.map((faq, index) => (
        <Grid item lg={4} md={6} key={index} style={{ position: 'relative' }}>
          <Box className="faq_box">
            <div className="faq_box_h">
              <img src={faq.imgSrc} alt={faq.alt} />
              <Typography variant="h5" component="div" color="white">
                {faq.title}
              </Typography>
            </div>
            <Typography variant="body2" color="white">
              {faq.description}
              {faq.linkText && (
                <>
                  <Link to={faq.linkHref}>{faq.linkText}</Link>
                </>
              )}
              {faq.extraDescription && (
                <>
                  <br />
                  <br />
                  {faq.extraDescription}
                  <Link to={faq.extraLinkHref}>{faq.extraLinkText}</Link>
                </>
              )}
            </Typography>
            {faq.hasImage && (
              <img src={faq.imgSrcExtra} alt="faq" className={`faq_img_${faq.imgSrcExtra.split('/').pop().split('.')[0]}`} />
            )}
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

export default FAQSection;
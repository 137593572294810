import React, { useState, useEffect } from "react";
import Layout from "../Layout";
import { scrollFunction, scrollToTop, scrollToSection } from "../scroll.js";
import { Link } from "react-router-dom";
import video from "../imgs/pipeline.mp4";
import helmet from "../imgs/medival_helmet.png";
import tiger from "../imgs/tiger.png";
import info from "../imgs/info.png";
import glb from "../imgs/glb_export.webp";
import racoon from "../imgs/raccoon_blend_preview.webp";
import Prism from "prismjs";
import "prismjs/components/prism-json";
import {faqData, ImageWithModal} from "../FAQ/artist.js"



function Artists() {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);
  const [showButton, setShowButton] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);

  const handleCollapsibleClick = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const jsonCode = `{
    name: "Christmas alert",
  }`;
  const highlightedCode = Prism.highlight(
    jsonCode,
    Prism.languages.json,
    "json"
  );

  useEffect(() => {
    const handleScroll = () => {
      setShowButton(scrollFunction());
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);


  return (
    <Layout>
      {showButton && (
        <button id="myBtn" onClick={scrollToTop} style={{ display: "block" }}>
          <svg
            style={{ color: "#7cd959" }}
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            fill="currentColor"
            className="bi bi-arrow-up-circle-fill"
            viewBox="0 0 16 16"
          >
            <path
              d="M16 8A8 8 0 1 0 0 8a8 8 0 0 0 16 0zm-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z"
              fill="#4fe83b"
            ></path>
          </svg>
        </button>
      )}
      <div className="leave-top_margin">
        <div className="streamer_header">
          <h1>
            Creating 3D alerts for <b className="highlighted">Streamfog</b>
          </h1>
        </div>
        <div className="streamer_body">
          <div className="table_contents">
            <h2 className="highlighted">Table of Contents</h2>
            <div className="table-of-contents__item">
              <Link to="#" onClick={() => scrollToSection("intro")}>
                1. Introduction
              </Link>
            </div>
            <div className="table-of-contents__item">
              <Link to="#" onClick={() => scrollToSection("tutorial")}>
                2. Tutorial
              </Link>
            </div>
            <ol className="setup_list">
              <li>
                {" "}
                <Link to="#" onClick={() => scrollToSection("classic_face")}>
                  Classic face
                </Link>
              </li>
              <li>
                <Link to="#" onClick={() => scrollToSection("advanced_face")}>
                  Advanced face
                </Link>
              </li>
              <li>
                <Link to="#" onClick={() => scrollToSection("3D")}>
                  3D Overlays
                </Link>
              </li>
            </ol>

            <div className="table-of-contents__item">
              <Link to="#" onClick={() => scrollToSection("faq")}>
                3. FAQ
              </Link>
            </div>
            <ul className="categories">
              <li>
                <Link
                  className="selected"
                  to="#"
                  onClick={() => scrollToSection("basics")}
                >
                  Basics
                </Link>
              </li>
              <li>
                <Link to="#" onClick={() => scrollToSection("keys")}>
                  Shape Keys
                </Link>
              </li>
              <li>
                <Link to="#" onClick={() => scrollToSection("export")}>
                  Export
                </Link>
              </li>
              <li>
                <Link to="#" onClick={() => scrollToSection("post")}>
                  Post
                </Link>
              </li>
              <li>
                <Link to="#" onClick={() => scrollToSection("size")}>
                  Size
                </Link>
              </li>
              <li>
                <Link to="#" onClick={() => scrollToSection("model")}>
                  Model
                </Link>
              </li>
            </ul>
            <div className="table-of-contents__item">
              <Link to="#" onClick={() => scrollToSection("join")}>
                4. Streamfog Creative Community
              </Link>
            </div>
          </div>
          <div className="table_body">
            <div className="table_html">
              <div>
                <h1 id="intro" className="highlighted">
                  Introduction
                </h1>
                <p>
                  Welcome to the Streamfog Artist's Guide, where your vision
                  breathes life into the live streaming universe. Here, you have
                  the unique opportunity to transform static streaming
                  backgrounds into captivating, dynamic scenes. Picture your
                  artistry unfolding in real-time, creating mesmerizing effects
                  that captivate streamers and their audiences alike.
                </p>
                <p>
                  Streamfog's 3D alerts utilize pre-rendered, semi-transparent
                  webm videos as overlays on the stream's back- and foreground,
                  creating an immersive experience where inanimate objects
                  appear to come to life.
                </p>
                <h4 className="highlighted">
                  Understanding Streamfog's Approach
                </h4>
                <p>
                  Our approach is based on a hard assumption that the scene
                  behind the streamer is static, a scenario true for 99% of all
                  streamers. By focusing on this specific use case, we have
                  massively simplified the problem, making 3D overlays not only
                  easy to create but also lightweight to use. The pre-rendering
                  process is done by talented 3D artists using tools like
                  Blender, allowing for a wide range of customization and
                  interaction that appears almost magical to the viewers.
                </p>
                <p>
                  See below, how the two layers that we introduced work behind
                  the scenes. We have a background.webm video that is being
                  played <i>behind</i> the streamer and a foreground.webm video
                  plays in front of him. You can include both layers in your
                  video or just one of the two. If your overlay contains both
                  videos, they play at the same time. Check the file format
                  section for more info!
                </p>
                <div className="responsive-video">
                  <video autoPlay loop muted>
                    <source src={video} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
              </div>
              <div className="separator"></div>

              <h1
                id="tutorial"
                className="highlighted"
                style={{ textAlign: "center" }}
              >
                Tutorial
              </h1>
              <ul style={{ marginTop: "40px" }}>
                <li style={{ listStyleType: "none" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <h3
                      id="classic_face"
                      style={{
                        fontSize: "24px",
                      }}
                    >
                      How to make and import a{" "}
                      <span className="highlighted">(classic) face</span> on the{" "}
                      <b className="highlighted">Streamfog app</b> ?
                    </h3>
                    <div className="info_p">
                      <img
                        src={info}
                        width="24px"
                        height="24px"
                        alt="info_icon"
                      />
                      <p>
                        In this tutorial, we are assuming that your face model
                        is done and ready to be animated using shape keys. Here
                        we are going to use Blender 4.0
                      </p>
                    </div>
                  </div>
                  <ul>
                    <li style={{ listStyleType: "none", margin: "40px 0px" }}>
                      <h4 className="highlighted">
                        1) Adding shape keys on your model.
                      </h4>
                      <p
                        style={{
                          textAlign: "left",
                          marginBottom: "80px",
                        }}
                      >
                        Shape keys are used to animate the face model according
                        to the streamers facial expressions. <br />
                        If you have active modifiers on your face model, make
                        sure to apply them all before adding the shape keys.
                      </p>
                      <div
                        style={{ display: "flex", gap: "10px" }}
                        className="adding_shape_video"
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                          className="responsive-video-container"
                        >
                          <iframe
                            width="600"
                            height="315"
                            src="https://www.youtube.com/embed/-QxzR-hM9ow?si=dzft98ddbbVwWvqj"
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerPolicy="strict-origin-when-cross-origin"
                            allowFullScreen
                          ></iframe>
                          <div className="info_p green">
                            <p>
                              <a
                                href="https://drive.google.com/file/d/1nKo8mq2QCMJtg0KLkMoF6yP0fXIorvWP/view"
                                target="_blank"
                                rel="noreferrer"
                              >
                                Download the Streamfog Utils addon for Blender
                                4.0
                              </a>
                            </p>
                          </div>
                        </div>
                        <div className="info_p img_container">
                          <img
                            src={info}
                            width="16px"
                            height="16px"
                            alt="info_icon"
                          />
                          <div>
                            <p>
                              Use this{" "}
                              <a
                                href="https://drive.google.com/file/d/1nI9JQA42mJzpP5Z68a6ZSX9e6zyHL3sf/view"
                                target="_blank"
                                rel="noreferrer"
                                className="highlighted"
                              >
                                .blend file
                              </a>{" "}
                              as a reference face model
                            </p>
                            <img
                              width={300}
                              height={150}
                              src={racoon}
                              alt="racoon"
                              className="racoon"
                            />
                          </div>
                        </div>
                      </div>
                    </li>
                    <li style={{ listStyleType: "none", margin: "40px 0px" }}>
                      <h4 className="highlighted">2) Header occluder</h4>
                      <p
                        style={{
                          textAlign: "left",
                          marginBottom: "80px",
                        }}
                      >
                        Depending of the face model, you may have to add an head
                        occluder in order to hide any 3D that goes inside our{" "}
                        <span className="highlighted">behind the head.</span>{" "}
                        <br />
                        The provided head occluder model is fitting with most of
                        the head shapes. You can adjust this occluder the way
                        you want but keep it named
                        <span className="highlighted">“head_occluder”</span>.
                      </p>
                      <p>Use cases:</p>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          gap: "10px",
                        }}
                      >
                        <div>
                          <img src={helmet} alt="Medival helmet" />
                          <p>
                            Head occluder
                            <br /> required
                          </p>
                        </div>
                        <div>
                          <img src={tiger} alt="Tiger" />
                          <p>
                            No head occluder
                            <br /> required
                          </p>
                        </div>
                      </div>
                    </li>
                    <li style={{ listStyleType: "none", margin: "40px 0px" }}>
                      <h4 className="highlighted">3) .GLB Export</h4>
                      <p>
                        Here is how I export my face model as a .glb file
                        format.
                      </p>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                        className="responsive-video-container"
                      >
                        <ImageWithModal
                          imgSrc={glb}
                          alt="glb_export_image"
                          width={506}
                          height={350}
                        />
                        <p
                          style={{
                            color: "#7cd959",
                            fontSize: "12px",
                            marginTop: "10px",
                            marginLeft: "7%",
                          }}
                        >
                          Good job ! You can now import your face on the
                          Streamfog app
                        </p>
                      </div>
                    </li>
                  </ul>
                </li>
              </ul>
              <div className="separator"></div>
              <ul>
                <li style={{ listStyleType: "none" }}>
                  <h3
                    id="advanced_face"
                    style={{
                      textAlign: "center",
                      fontSize: "24px",
                      marginBottom: "40px",
                    }}
                  >
                    How to make and import a{" "}
                    <span className="highlighted">(advanced) face</span> on the{" "}
                    <b className="highlighted">Streamfog app</b> ?
                  </h3>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                    className="responsive-video-container"
                  >
                    <iframe
                      width="937"
                      height="529"
                      src="https://www.youtube.com/embed/TImMJ6mD1HA?si=7Cqjc9h33mHeQp2W"
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      referrerPolicy="strict-origin-when-cross-origin"
                      allowFullScreen
                    ></iframe>
                    <div
                      className="info_p"
                      style={{
                        width: "50%",
                        display: "flex",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <img
                        src={info}
                        width="24px"
                        height="24px"
                        alt="info_icon"
                      />
                      <p>
                        It is highly recommended to follow the classic face
                        tutorial before this one.
                      </p>
                    </div>
                  </div>
                </li>
              </ul>
              <div className="separator"></div>
              <ul>
                <li style={{ listStyleType: "none" }}>
                  <h3
                    id="3D"
                    style={{
                      textAlign: "center",
                      fontSize: "24px",
                      marginBottom: "40px",
                    }}
                  >
                    How to make and import a custom 3d overlay animation on the{" "}
                    <b className="highlighted">Streamfog app</b> ?
                  </h3>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                    className="responsive-video-container"
                  >
                    <iframe
                      width="937"
                      height="529"
                      src="https://www.youtube.com/embed/yqLmTBsxWWY?si=KlXeJ0l5yx38yfkY"
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      referrerPolicy="strict-origin-when-cross-origin"
                      allowFullScreen
                    ></iframe>
                    <div className="info_p" style={{ width: "70%" }}>
                      <img
                        src={info}
                        width="24px"
                        height="24px"
                        alt="info_icon"
                      />
                      <p>
                        This video tutorial explain how to prepare an alert
                        using specific tools. You can use other softwares than
                        the ones shown in this video.
                      </p>
                    </div>
                  </div>
                </li>
                <li style={{ marginTop: "60px", listStyleType: "none" }}>
                  <h4 style={{ fontSize: "16px" }}>Fileformat</h4>
                  <p>
                    Creating 3D alerts with Streamfog requires careful attention
                    to the file format. An alert is a zip file containing the
                    actual assets. You are allowed to include the
                    background.webm file or the foreground.webm file, you can
                    also chose to include both if you want things to happen
                    behind and in front of the streamer. At least one video is
                    therefore mandatory. Preview.gif, thumbnail.jpg and
                    meta.json also need to be part of the zip file. Please make
                    sure you follow the exact naming conventions.
                  </p>
                  <p>
                    To see an example file structure, you can download the
                    Christmas alert shown above.
                    <a href="https://streamfog-public-share.s3.us-east-2.amazonaws.com/Christmas_-_Santas_Tour-1.0.0.zip">
                      {" "}
                      Download Example
                    </a>
                    .
                  </p>

                  <ul>
                    <li>
                      <b className="highlighted">background.webm</b>
                      <p>
                        As described earlier, this video will play{" "}
                        <i>behind </i>
                        the streamer. Make sure it contains the alpha channel if
                        you want the streamers room to be partly visible. The
                        video file should be optimized to be as small as
                        possible, ideally below 10 MB. Larger files may cause
                        delays in the alert's appearance and may affect
                        performance.
                      </p>
                    </li>
                    <li>
                      <b className="highlighted">foreground.webm</b>
                      <p>
                        The same as above, only this video is being played in
                        front of the streamer.
                      </p>
                    </li>
                    <li>
                      <b className="highlighted">thumbnail.jpg</b>
                      <p>
                        The thumbnail image gives a quick preview of the alert
                        and makes it more appealing inside our app. It will also
                        help the alert shine on platforms like Elgato
                        Streamdeck.
                      </p>
                    </li>
                    <li>
                      <b className="highlighted">preview.gif</b>
                      <p>
                        Similar to the thumbnail, the preview gif offers a
                        dynamic visual cue of what the alert looks like, adding
                        an extra touch of professionalism to your presentation
                        within the Streamfog platform.
                      </p>
                    </li>
                    <li>
                      <b className="highlighted">meta.json</b>
                      <p>
                        The meta.json file contains essential metadata. As of
                        now, there is only the name encoded into the json file.
                      </p>
                      <p>Here's an example meta.json:</p>
                    </li>
                  </ul>

                  <pre>
                    <code
                      className="language-json"
                      dangerouslySetInnerHTML={{ __html: highlightedCode }}
                    />
                  </pre>
                </li>
              </ul>

              <div className="separator"></div>

              <div className="faq-container">
                <h1
                  id="faq"
                  className="highlighted"
                  style={{ textAlign: "center" }}
                >
                  FAQ
                </h1>
                {faqData.map((section, sectionIndex) => (
                  <ul key={section.id} className="faq-group">
                    <li className="faq-title" style={{ listStyleType: "none" }}>
                      <h4 id={section.id}>{section.title}</h4>
                      {section.questions.map((item, index) => {
                        const overallIndex = `${sectionIndex}-${index}`;
                        return (
                          <div key={overallIndex} className="question">
                            <button
                              className={`collapsible ${
                                activeIndex === overallIndex ? "active" : ""
                              }`}
                              onClick={() =>
                                handleCollapsibleClick(overallIndex)
                              }
                            >
                              {item.question}
                            </button>
                            {activeIndex === overallIndex && (
                              <div className="content">
                                {typeof item.answer === "string" ? (
                                  <p>{item.answer}</p>
                                ) : (
                                  item.answer
                                )}
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </li>
                  </ul>
                ))}
              </div>
              <div className="separator"></div>
              {/* Community part */}
              <div>
                <h1 id="join" className="highlighted">
                  Join the Streamfog Creative Community
                </h1>
                <p>
                  Are you a 3D artist with a passion for gaming and a love for
                  the vibrant world of Twitch streaming? At Streamfog, we're
                  making those dreams a reality, and we want you to be part of
                  this exhilarating journey.
                </p>
                <h4 className="highlighted">
                  Contribute to Our Free Marketplace
                </h4>
                <p>
                  We invite artists immersed in gaming to add their 3D alerts to
                  our free marketplace. By doing so, you'll showcase your work
                  to gamers and streamers worldwide, advertise your talents, and
                  allow streamers to approach you for custom, high-quality
                  3D-Art.
                </p>
                <h4 className="highlighted">
                  Collaborate as an Independent Artist
                </h4>
                <p>
                  Interested in infusing your game-inspired art into real-world
                  experiences without any constraints? Consider collaborating
                  with us as an independent artist. With very few strings
                  attached, we'd like to keep a portfolio of talented artists
                  who we can approach for custom requests our platform receives.
                  Join our network, and be part of a flexible collaboration that
                  puts your creativity at the forefront of interactive gaming
                  and streaming experiences. Your art, your rules; we're here to
                  make the connection!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Artists;

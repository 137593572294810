import React, { useState, useEffect } from "react";
import { Card, CardContent, Box } from "@mui/material";

const testimonials = [
  {
    quote:
      "A campaign that drove strong engagement and contributed to Uber Eats' best performing weeks in Germany.",
  },
  {
    quote:
      "We love the AR effects and the talents had so much fun with their viewers.",
  },
  {
    quote: "WOW, these AR visuals look sick!",
  },
];

const TestimonialRotator = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1
      );
    }, 7000); // Rotate every 5 seconds

    return () => clearInterval(timer);
  }, []);

  return (
<Box display="flex" justifyContent="center" >
  <Card
    className="max-w-md"
    sx={{
      backgroundColor: 'transparent',
      backdropFilter: "blur(12px)",
      borderRadius: "12px",
      boxShadow: "0px 0px 28px rgba(0, 0, 0, 0.45)",
      color: "#7cd959",
      height: "170px",
      marginTop: "-50px",
      width:"400px",
      marginLeft:"15%"
    }}
  >
    <CardContent className="p-6 relative">
      <div className="flex flex-col justify-center">
        <p className="text-lg mb-4">
          <em>"{testimonials[currentIndex].quote}"</em>
        </p>
        <p className="text-lg mb-2" style={{ textAlign: 'right', fontWeight: "bold" }}>
          {/* <em> - {testimonials[currentIndex].author}</em> */}
        </p>
      </div>
    </CardContent>
  </Card>
</Box>

  );
};

export default TestimonialRotator;

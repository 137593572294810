import React, { useState } from "react";
import Layout from "../Layout";
import { Link } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";

const SearchBar = ({ data, onSearch }) => {
  const [query, setQuery] = useState("");

  const handleInputChange = (event) => {
    const newQuery = event.target.value;
    setQuery(newQuery);

    const filteredData = data.filter((item) => {
      const lowerCaseQuery = newQuery.toLowerCase();
      const titleIncludesQuery = item.title
        .toLowerCase()
        .includes(lowerCaseQuery);
      const categoryIncludesQuery = item.category
        .toLowerCase()
        .includes(lowerCaseQuery);

      return titleIncludesQuery || categoryIncludesQuery;
    });

    onSearch(filteredData);
  };

  return (
    <div style={{ position: "relative" }}>
      <input
        className="search_in"
        type="text"
        value={query}
        onChange={handleInputChange}
      />
      <SearchIcon className="search_icon" />
    </div>
  );
};
// Feature Blog
const LatestBlog = ({ blogs }) => {
  const sortedBlogs = blogs.sort((a, b) => new Date(b.dt) - new Date(a.dt));
  const latestBlog = sortedBlogs[0];

  return (
    <>
      {latestBlog && (
        <Link
          key={latestBlog.id}
          to={`/blog/${latestBlog.id}#top`}
          style={{ textDecoration: "none" }}
        >
          <div className="wrap">
            <div className="image-container">
              <img src={latestBlog.image} alt="blog" />
            </div>
            <div className="box">
              <h2 className="box-h">{latestBlog.title}</h2>
              <p className="box-con">{latestBlog.content.slice(0, 180)}....</p>
              <span className="box-dt">{latestBlog.dt}</span>
              <p>
                <span className="badge">{latestBlog.category}</span>
              </p>
              <button className="read-more">
                Read more
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div>
          </div>
        </Link>
      )}
    </>
  );
};
export default function Blog({ blogs }) {
  const [filteredBlogs, setFilteredBlogs] = useState(blogs);

  const handleSearch = (filteredData) => {
    setFilteredBlogs(filteredData);
  };

  return (
    <Layout>
      <div className="blog-con">
        <h1 style={{ marginLeft: "6%", fontSize:"24px" }}>
          <b className="highlighted">POPULAR BLOG</b>
        </h1>
        <SearchBar data={blogs} onSearch={handleSearch} />
      </div>
      <LatestBlog blogs={blogs} />
      <h1 className="recent_h">
        <b className="highlighted">RECENT BLOGS</b>
      </h1>
      <section className="blogs">
        {filteredBlogs.map((item) => (
          <Link
            key={item.id}
            to={`/blog/${item.id}#top`}
            onClick={() => window.scrollTo(0, 0)}
            style={{ color: "#fff", textDecoration: "none" }}
          >
            <article>
              <div className="article-wrapper">
                <figure>
                  <img src={item.image} alt="" />
                </figure>
                <div className="article-body">
                  <h2>{item.title}</h2>
                  <p>
                    <span className="badge">{item.category} </span>{" "}
                  </p>
                  <span style={{ fontSize: "10px" }}>{item.dt}</span>
                  <p className="bigger">{item.content.slice(0, 180)}...</p>
                  <button className="read-more">
                    Read more
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </article>
          </Link>
        ))}
      </section>
    </Layout>
  );
}

import React from 'react';
import { Link } from "react-router-dom";
import { Grid, Box, Typography } from '@mui/material';
import question from '../imgs/question_mark.svg';

const faqData = [
  {
    imgSrc: question,
    alt: 'book',
    title: 'How much does it cost?',
    description: 'Streamfog is free. You can buy custom assets though.',


  },
  {
    imgSrc: question,
    alt: 'book',
    title: 'What are System requirements?',
    description: 'Using OBS is the only real requirement.',

  },
  {
    imgSrc:question,
    alt: 'book',
    title: 'Can Streamfog be used with streaming platforms other than Twitch?',
    description: 'Yes, although we are currently only supporting Login with Twitch and Twitch Events for alerts. If you’re streaming on Kick or Youtube, just log in with any twitch account.',

    linkText: 'YouTube',
    linkHref: 'https://www.youtube.com/@streamfog'
  },
  {
    imgSrc: question,
    alt: 'book',
    title: 'Does Streamfog transmit my camera feed to external servers, or is it processed locally?',
    description: '100% locally. All the processing is happening within the browser source on your PC. You can test that by disabling WiFi after your browser source is initialized. ',

  },
  {
    imgSrc: question,
    alt: 'book',
    title: 'How can I create custom alerts?',
    description: 'Custom assets can be created with basic blender knowledge. We have some tutorials ',

    linkText: 'here',
    linkHref: '/streamers',
    extraDescription: 'Our internal 3D art studio currently offers custom commissions for $200 per asset. You can order those at : https://app.streamfog.com under “Get custom Alert” ',
    extraLinkText: 'here',
    extraLinkHref: 'https://app.streamfog.com',
  },
  {
    imgSrc: question,
    alt: 'book',
    title: 'How does Streamfog differ from Snap Camera?',
    description: 'Streamfog is easier to set up, runs more efficiently and is more open to custom assets. Snap Camera also never really had the background animations we have. Finally, we created Streamfog specifically for streamers, we love to listen to your feedback on our discord and are also actively working on 3rd party integrations with partners like Elgato, Streamer.bot and Throne.',

  }

]



const FAQStreamer = () => {
  return (
    <Grid container spacing={2}>
      {faqData.map((faq, index) => (
        <Grid item lg={4} md={6} key={index} style={{ position: 'relative' }}>
          <Box className="faq_box" style={{padding:"20px 10px"}}>
            <div className="faq_box_h">
              <img src={faq.imgSrc} alt={faq.alt} />
              <Typography variant="h6" component="div" color="white" >
                {faq.title}
              </Typography>
            </div>
            <Typography variant="body2" color="white" style={{ marginLeft: '15%', fontSize:"12px", fontWeight:"400", opacity:"1",lineHeight:"1.5", letterSpacing:"1.5px"  }}>
              {faq.description}
              {faq.linkText && (
                <>
                  <Link to={faq.linkHref}>{faq.linkText}</Link>
                </>
              )}

              {faq.extraDescription && (
                <>
                  <br />
                  <br />
                  {faq.extraDescription}
                  <Link to={faq.extraLinkHref}>{faq.extraLinkText}</Link>
                </>
              )}

            </Typography>
            {faq.hasImage && (
              <img src={faq.imgSrcExtra} alt="faq" className={`faq_img_${faq.imgSrcExtra.split('/').pop().split('.')[0]}`} />
            )}
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

export default FAQStreamer;
import React, { useRef, useEffect } from "react";
import Layout from "../Layout";
import { Link } from "react-router-dom";
import getStarted from "../imgs/get_started_btn.webp";
import CookieConsent from "react-cookie-consent";
import sf_demo from "../imgs/sf_demo.mp4";
import zombie from "../imgs/zombie.webm";
import avatar from "../imgs/avatar_demo.webm";
import twitch from "../imgs/twitchExtnsn.webm";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import tl from "../imgs/top_left_faq.webp";
import tr from "../imgs/top_right_faq.webp";
import hero_bg from "../imgs/bg_round_shapes_new.svg";
import hero_girl from "../imgs/hero-girl.webp";
import hero_boy from "../imgs/hero-boy.webp";
import checkmark from "../imgs/checkmark.png";
import coinImg from "../imgs/coins.webp";
import thunderImg from "../imgs/thunder.webp";
import maskImg from "../imgs/mask.webp";
import nvidia from "../imgs/nvidia-inception-program-badge-rgb-for-screen (1).png";
import techstars from "../imgs/techstars.svg";
import FAQSection from "../FAQ/faq";
//import snapLogo from "../imgs/snap_logo.svg";

function LandingPage() {
  const featuresRef = useRef(null);
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  const btnClick = () => {
    // window.location.href = 'http://localhost:3000/giveaway';
    window.location.href = "https://streamfog.com/giveaway";
  };

  return (
    <Layout>
      <div onClick={btnClick} className="giveaway-btn">
        {/* <GiveawayBtn /> */}
      </div>
      <section className="new-hero-section">
        <img src={hero_bg} alt="hero-bg" className="background_image" />
        <img src={hero_girl} alt="hero-bg" className="hero_girl" />
        <img src={hero_boy} alt="hero-bg" className="hero_boy" />
        <div className="new-hero-wrapper">
          <h1 className="hero_title">
            Upgrade Your Stream With <span>AR</span>
          </h1>
          <ul className="benefits">
            <li>
              Free <img src={checkmark} alt="" />
            </li>
            <li>
              No Download <img src={checkmark} alt="" />
            </li>
            <li>
              Added to OBS in 2min <img src={checkmark} alt="" />
            </li>
            <li>
              Twitch Extension <img src={checkmark} alt="" />
            </li>
          </ul>
          <div className="snap_logo">
            <Link to={"https://app.streamfog.com"} target="_blank">
              <img src={getStarted} alt="getstarted" />
            </Link>
            {/* <img
              src={snapLogo}
              alt="snap-Logo"
              style={{ width: "100px", marginLeft:"10px" }}
            /> */}
          </div>
        </div>
        <div
          className="scroll-down"
          onClick={() =>
            featuresRef.current.scrollIntoView({
              behavior: "smooth",
              block: "center",
              inline: "center",
            })
          }
        >
          <div className="animate">Scroll Down</div>{" "}
        </div>
      </section>

      {/* <!--Just for Desktop Separate Section--> */}
      <section className="section_features_main" ref={featuresRef}>
        <div className="container-fluid">
          <img src={coinImg} className="coin-img" alt="" />
          <img src={thunderImg} className="thunder-img" alt="" />
          <img src={maskImg} className="mask-img" alt="" />
          <h6
            className="section-title"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            Features
          </h6>

          <Grid container spacing={12} justifyContent="center">
            <Grid item md={5}>
              <p className="feature-title">
                <span>AR</span> Animation
              </p>
              <div className="feature_video-wrapper">
                <video
                  autoPlay="autoplay"
                  loop
                  muted
                  controls={window.innerWidth < 767 ? "controls" : false}
                  // playsInline={true}
                  playsInline
                  preload="auto"
                  className="feature-video green-shadow"
                  width="100%"
                  webkit-playsinline="true"
                  x-webkit-airplay="true"
                >
                  <source src={sf_demo} type="video/webm" />
                </video>
              </div>
              <span className="feature-decs">
                Streamfog provides a quantity of unique alerts. From generic to
                specific animations. You can even import your own !
              </span>
            </Grid>

            <Grid item md={5}>
              <p className="feature-title">
                Face <span>Filters</span>
              </p>
              <div className="feature_video-wrapper">
                <video
                  autoPlay="autoplay"
                  loop
                  muted
                  controls={window.innerWidth < 767 ? "controls" : false}
                  playsInline
                  preload="auto"
                  className="feature-video green-shadow"
                  width="100%"
                  webkit-playsinline="true"
                  x-webkit-airplay="true"
                >
                  <source src={avatar} type="video/webm" />
                </video>
              </div>
              <span className="feature-decs">
                Enhance your face with a exclusive face filters.
              </span>
            </Grid>
            <Grid item md={5}>
              <p className="feature-title">
                Custom Faces with <span>AI</span>
              </p>
              <div className="feature_video-wrapper">
                <video
                  autoPlay="autoplay"
                  loop
                  muted
                  controls={window.innerWidth < 767 ? "controls" : false}
                  playsInline
                  preload="auto"
                  className="feature-video green-shadow"
                  width="100%"
                  webkit-playsinline="true"
                  x-webkit-airplay="true"
                >
                  <source src={zombie} type="video/webm" />
                </video>
              </div>
              <span className="feature-decs">
                Be creative and make your own custom faces
              </span>
            </Grid>

            <Grid item md={5}>
              <p className="feature-title">
                <span>
                  <Link
                    to={
                      "https://dashboard.twitch.tv/extensions/98a24x5ay115u9exm1tpf2dpockph1-0.0.2"
                    }
                    target="_blank"
                    className="twitchextnsn"
                  >
                    Twitch
                  </Link>
                </span>{" "}
                Extension
              </p>
              <div className="feature_video-wrapper">
                <video
                  autoPlay="autoplay"
                  loop
                  muted
                  controls={window.innerWidth < 767 ? "controls" : false}
                  playsInline
                  preload="auto"
                  className="feature-video green-shadow"
                  width="100%"
                  webkit-playsinline="true"
                  x-webkit-airplay="true"
                >
                  <source src={twitch} type="video/webm" />
                </video>
              </div>
              <span className="feature-decs">
                Install the{" "}
                <Link
                  to={
                    "https://dashboard.twitch.tv/extensions/98a24x5ay115u9exm1tpf2dpockph1-0.0.2"
                  }
                  target="_blank"
                  className="twitchextnsn_green"
                >
                  Streamfog extension
                </Link>{" "}
                now and let users trigger your effects. Bits supported.
              </span>
            </Grid>
          </Grid>
        </div>
      </section>
      {/* <!-- FAQ Section --> */}
      <section className="section-picture">
        <div className="desktop-faqs">
          <div className="container-fluid">
            <img src={tr} alt="faq" className="faq_img_tr" />
            <img src={tl} alt="faq" className="faq_img_tl" />
            <div className="section-title-div">
              <h6 className="section-title mb-3">Frequently asked Questions</h6>
              <Typography className="section-subtitle" sx={{ fontWeight: 700 }}>
                Quick answers to questions you may have. Feel free to join our
                <Link to="https://discord.gg/Z68pjTHfuK" target="_blank">
                  {" "}
                  Discord{" "}
                </Link>{" "}
                server for further assistance
              </Typography>
            </div>

            <FAQSection />
          </div>
        </div>
      </section>
      {/* Brands  */}
      <section
        className="new-hero-section"
        style={{ minHeight: "80vh", marginTop: "-3%" }}
      >
        <img
          src={hero_bg}
          alt="hero-bg"
          className="background_image brand_backimg"
        />
        <div className="new-hero-wrapper mobile_brands desktop_brands">
          <img
            src={techstars}
            alt="NVIDIA_BADGE"
            className="nvidia_badge techstars"
            width={200}
            height={70}
          />
          <img
            src={nvidia}
            alt="NVIDIA_BADGE"
            className="nvidia_badge nvidia"
            width={200}
            height={70}
          />{" "}
        </div>
      </section>

      <CookieConsent
        enableDeclineButton
        flipButtons
        style={{
          background: "#171d21",
          display: "block",
          textAlign: "center",
          maxWidth: 300,
          padding: "10px 10px 30px",
          boxShadow: "0px 0px 20px rgba(0,0,0,0.2)",
          left: 20,
          marginBottom: 20,
          border: "1px solid #7cd959",
        }}
        buttonStyle={{
          background: "#7cd959",
          fontWeight: 700,
          margin: 0,
        }}
        declineButtonStyle={{ fontWeight: 700, margin: 0, marginLeft: 20 }}
      >
        <h5
          style={{ opacity: 0.9, textTransform: "uppercase", color: "#7cd959" }}
        >
          We use cookies
        </h5>
        <p style={{ opacity: 0.9, marginBottom: 0 }}>
          Cookies help us deliver the best experience on our website. By using
          our website, you agree to the use of cookies.
        </p>
      </CookieConsent>
    </Layout>
  );
}

export default LandingPage;

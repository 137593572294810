import React, { useEffect, useState } from "react";
import Layout from "../Layout";
import { scrollFunction, scrollToTop, scrollToSection } from "../scroll.js";
import { Link } from "react-router-dom";

function Terms() {
  const [showButton, setShowButton] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      setShowButton(scrollFunction());
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <Layout>
      {showButton && (
        <button id="myBtn" onClick={scrollToTop} style={{ display: "block" }}>
          <svg
            style={{ color: "#7cd959" }}
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            fill="currentColor"
            className="bi bi-arrow-up-circle-fill"
            viewBox="0 0 16 16"
          >
            <path
              d="M16 8A8 8 0 1 0 0 8a8 8 0 0 0 16 0zm-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z"
              fill="#4fe83b"
            ></path>
          </svg>
        </button>
      )}
      <div className="streamer_header">
        <h1>
          <b className="highlighted">Streamfog</b> Terms Of Service
        </h1>
      </div>
      <div className="streamer_body">
        <div className="table_contents">
          <h2 className="highlighted">Table of Contents</h2>
          <div className="table-of-contents__item">
            <Link to="#" onClick={() => scrollToSection("intro")}>
              1.  Introduction
            </Link>
          </div>
          <div className="table-of-contents__item">
            <Link to="#" onClick={() => scrollToSection("terms")}>
              2.  Acceptance of Terms{" "}
            </Link>
          </div>
          <div className="table-of-contents__item">
            <Link to="#" onClick={() => scrollToSection("other")}>
              3.  These Terms and Our Other Policies
            </Link>
          </div>
          <div className="table-of-contents__item">
            <Link to="#" onClick={() => scrollToSection("user")}>
              4.  User Requirements
            </Link>
          </div>
          <ul className="setup_list">
            <li>
              <Link to="#" onClick={() => scrollToSection("age")}>
                Age, Parent Responsibility
              </Link>
            </li>
            <li>
              <Link to="#;" onClick={() => scrollToSection("prohibition")}>
                Prohibitions, Unlawful Use
              </Link>
            </li>
            <li>
              <Link to="#;" onClick={() => scrollToSection("closure")}>
                Account Closure
              </Link>
            </li>
          </ul>
          <div className="table-of-contents__item">
            <Link to="#;" onClick={() => scrollToSection("services")}>
              5.  Streamfog Services
            </Link>
          </div>
          <div className="table-of-contents__item">
            <Link to="#;" onClick={() => scrollToSection("give")}>
              6.  Official Giveaway Rules
            </Link>
          </div>
          <div className="table-of-contents__item">
            <Link to="#;" onClick={() => scrollToSection("behavior")}>
              7. User Behaviour
            </Link>
          </div>
          <div className="table-of-contents__item">
            <Link to="#;" onClick={() => scrollToSection("content")}>
              8.User Content
            </Link>
          </div>
          <div className="table-of-contents__item">
            <Link to="#;" onClick={() => scrollToSection("intel")}>
              9.Intellectual Property
            </Link>
            <ul className="setup_list">
              <li>
                <Link to="#;" onClick={() => scrollToSection("legal")}>
                  Compliance with Legal Norms
                </Link>
              </li>
              <li>
                <Link to="#" onClick={() => scrollToSection("property")}>
                  Streamfog's Intellectual Property
                </Link>
              </li>
              <li>
                <Link to="#;" onClick={() => scrollToSection("open")}>
                  Open Source Software
                </Link>
              </li>
              <li>
                <Link to="#;" onClick={() => scrollToSection("feedback")}>
                  Feedback
                </Link>
              </li>
              <li>
                <Link to="#;" onClick={() => scrollToSection("paid")}>
                  Paid Services and Charges
                </Link>
              </li>
              <li>
                <Link to="#;" onClick={() => scrollToSection("reliable")}>
                  Reliability of Billing and Account Details
                </Link>
              </li>

              <li>
                <Link to="#;" onClick={() => scrollToSection("fees")}>
                  Service Fees
                </Link>
              </li>
              <li>
                <Link to="#;" onClick={() => scrollToSection("taxes")}>
                  Taxes
                </Link>
              </li>
              <li>
                <Link to="#;" onClick={() => scrollToSection("auto")}>
                  Auto-Renewal of Subscription
                </Link>
              </li>
              <li>
                <Link to="#;" onClick={() => scrollToSection("refund")}>
                  Refund Policy
                </Link>
              </li>
              <li>
                <Link to="#;" onClick={() => scrollToSection("changes")}>
                  Changes in Fees
                </Link>
              </li>
              <li>
                <Link to="#;" onClick={() => scrollToSection("charge")}>
                  Chargebacks
                </Link>
              </li>
              <li>
                <Link to="#" onClick={() => scrollToSection("payment")}>
                  Payment Processing and Credit Card Conditions
                </Link>
              </li>
              <li>
                <Link to="#;" onClick={() => scrollToSection("third")}>
                  Fees for Third-Party Services
                </Link>
              </li>
              <li>
                <Link to="#;" onClick={() => scrollToSection("indem")}>
                  Indemnity
                </Link>
              </li>
              <li>
                <Link to="#;" onClick={() => scrollToSection("limitation")}>
                  Limitation of Responsibility
                </Link>
              </li>
              <li>
                <Link to="#" onClick={() => scrollToSection("dispute")}>
                  Dispute Resolution
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="table_body">
          <div className="table_html">
            <div>
              <h1 id="intro" className="highlighted">
                Introduction
              </h1>
              <p>
                The following document sets out the terms of use for all
                Streamfog Services. Before using any of our Services, you are
                required to read, understand, and agree to these terms. When we
                say “Streamfog,” “we,” “us,” and “our” in these terms, we mean
                Streamfog UG (haftungsbeschränkt). When we say “Services” in
                these terms, we mean Streamfog’s services, apps, websites, and
                all other products. When we say “Platform”, we mean the Platform
                managed by Streamfog to provide the Services, including all
                software and computer hardware on which applications are run or
                installed. Questions about these Terms of Service should be sent
                to support@streamfog.com.
              </p>
            </div>
            <hr className="horizontal_line" />
            <div>
              <h1 id="terms" className="highlighted">
                Acceptance Of Terms
              </h1>

              <p>
                In these terms, when we say “you” or “your,” we mean you. If
                you’re accessing our Services on behalf of a legal entity (like
                your company), you hereby agree and represent to us that you
                have the authority to bind that entity to these terms, and “you”
                and “your” will refer to that entity.
                <br />
                <b>
                  IMPORTANT NOTE: By using the Services, you are agreeing, on
                  behalf of yourself and those you represent, to comply with and
                  be legally bound by these terms as well as our privacy policy
                  and all applicable laws. <br />
                  If you, for yourself or on behalf of those you represent, do
                  not agree to any provision of these terms, you must, for
                  yourself and on behalf any such person(s), cancel the
                  registration process, discontinue your use of the services,
                  and, if you are already registered, cancel your account
                </b>
              </p>
            </div>
            <hr className="horizontal_line" />
            <div>
              <h1 id="other" className="highlighted">
                These Terms and Our Other Policies
              </h1>

              <p>
                We also have a privacy policy that applies to your use of our
                Services and are incorporated into these terms as if fully set
                forth in these terms and you agree to them when you agree to
                these terms. Please read the privacy policy.
                <br />
                <b>
                  Developers on our app store are also subject to additional
                  developer terms and conditions.
                  <br />
                  IMPORTANT NOTE: The section titled “Disputes” contains an
                  arbitration clause and className-action waiver that applies to
                  all users. Please read this section carefully as it
                  significantly affects your legal rights, including your right
                  to file a lawsuit in court
                </b>
              </p>
            </div>
            <hr className="horizontal_line" />
            <div>
              <h1 id="user" className="highlighted">
                User Requirements
              </h1>
              <ul style={{ listStyle: "none" }}>
                <li>
                  <h2 id="obs">Age, Parent Responsibility</h2>
                  <p>When you utilize our services, you assure us that:</p>
                  <ul>
                    <li>you are not under the age of 16; and</li>
                    <li>
                      you fulfil the age criteria set by your country to use
                      these services.
                    </li>
                  </ul>
                  <p>
                    In the event you are between the age of 16 and 18 or lack
                    the capacity to accept these terms:
                  </p>
                  <ul>
                    <li>
                      kindly have a parent or guardian review these terms with
                      you,
                    </li>
                    <li>
                      the use of Services must be under the oversight of a
                      parent or legal guardian who consents to these Terms of
                      Service.
                    </li>
                  </ul>
                  <p>
                    NOTE TO PARENTS: As a parent or legal guardian, if your
                    child uses our services, these terms are applicable to you,
                    and you are held accountable for your child’s activities on
                    the services
                  </p>
                </li>
                <li>
                  <h2 id="obs">Prohibitions, Unlawful Use</h2>
                  <p>
                    If you have been previously suspended or removed from the
                    Services due to a violation of our terms, access to any
                    Services is not permitted without Streamfog's explicit
                    written consent via email. Please reach out to
                    support@streamfog.com for assistance.
                  </p>
                  <p>
                    Streamfog Services are not accessible to individuals who are
                    legally prohibited from receiving them under the laws of the
                    United States or relevant laws in any other jurisdiction.
                  </p>
                  <p>
                    Our service must not be used for any illegal or unauthorised
                    purpose, and you may not breach any laws in your
                    jurisdiction while using our Service.
                  </p>
                </li>

                <li>
                  <h2 id="obs">Account Closure</h2>
                  <p>
                    You can request the deletion of your account by reaching out
                    to support@streamfog.com.
                  </p>
                  <p>
                    As per applicable laws, we retain the right, at our
                    discretion, to suspend or terminate your account and/or your
                    access to some or all of our services with or without prior
                    notification, including if:
                  </p>
                  <ul>
                    <li>
                      You fail to adhere to these terms, any Streamfog policy,
                      or additional terms applicable to specific products;
                    </li>
                    <li>
                      We're legally obligated to do so to meet a legal
                      requirement or court order;
                    </li>
                    <li>
                      We reasonably believe termination is necessary to mitigate
                      harm to you, us, other users, or third parties
                    </li>
                  </ul>
                  <p>
                    We will, however, notify you in advance if such a
                    requirement exists under applicable law.
                  </p>
                </li>
                <p>
                  Any obligations and liabilities that occurred prior to the
                  termination date will remain post the termination of this
                  agreement for all purposes.
                </p>
              </ul>
            </div>
            <hr className="horizontal_line" />
            <div>
              <h1 id="services" className="highlighted">
                Streamfog Services
              </h1>
              <ul style={{ listStyle: "none" }}>
                <li>
                  <h2 id="obs">Potential Changes to Services</h2>
                  <p>
                    We are continually striving to enhance Streamfog. There
                    might be additions or removals of features, launching of new
                    services, or constraints or discontinuations of existing
                    services. Although we strive to limit disruptions, we cannot
                    promise that there won't be any service outage, planned
                    downtime, or modifications to the services, and it may not
                    be possible to retrieve your content due to such disruptions
                    or changes. We bear no liability for such outages, planned
                    downtime, or service changes.
                  </p>
                </li>
                <li>
                  <h2 id="obs">Price Modifications</h2>
                  <p>
                    Prices and/or fees for our Service may be modified at any
                    time.
                  </p>
                </li>

                <li>
                  <h2 id="obs">
                    Accuracy of Information on Our Site
                  </h2>
                  <p>
                    We make every effort to keep our websites up-to-date, but we
                    cannot assure that information on any Streamfog site is
                    precise, complete, or up-to-date. You rely on the material
                    on Streamfog sites at your own risk.
                  </p>
                  <p>
                    These sites may host some historical information. Such
                    information, by nature, is not up-to-date and is given for
                    your reference only. We have the right to alter the contents
                    of these sites at any moment, but we are under no obligation
                    to update any information on our sites.
                  </p>
                  <p>
                    Without our express written permission, you agree not to
                    replicate, duplicate, copy, sell, resell, or exploit any
                    portion of the Service, use of the Service, or access to the
                    Service or any contact on the website through which the
                    service is provided.
                  </p>
                </li>
                <li>
                  <h2 id="obs">Disclaimer of Warranties</h2>
                  <p>
                    To the maximum extent permitted by law, Streamfog, its
                    affiliates, and their respective suppliers offer no
                    warranties, either explicit or implied, about the services.
                    The services are provided "as is." We disclaim any implied
                    warranties of merchantability, fitness for a particular
                    purpose, quiet enjoyment, and non-infringement, and any
                    warranties arising out of course of dealing or usage of
                    trade. If the laws of certain jurisdictions or states do not
                    permit limitations on implied warranties, we limit the
                    duration and remedies of such warranties to the greatest
                    extent allowed under those laws.
                  </p>
                </li>
                <li>
                  <h2 id="obs">
                    User Content and Services are Delivered “As Is”
                  </h2>
                  <p>
                    Unless demanded by the law, Streamfog will not be held
                    responsible for, nor is Streamfog obligated to screen,
                    approve, edit, or control User Content that you or others
                    upload or share on the Services.
                  </p>
                  <p>
                    Streamfog, however, may at any given time without notice or
                    obligation to you, remove, partly censor, or suspend or
                    block the availability of any User Content that Streamfog
                    believes violates its terms or policies or deems
                    inappropriate.
                  </p>
                  <p>
                    When using the Services, you accept that you will encounter
                    User Content from various sources and understand that this
                    User Content might be inaccurate, offensive, or
                    objectionable. You agree to relinquish any legal or
                    equitable right or remedy that you may have against
                    Streamfog concerning User Content.
                  </p>
                  <p>
                    This warranty does not affect any consumer rights you may
                    have under the law, including the legal guarantee in certain
                    areas that products and services must comply with this
                    agreement and your rights in case a product or service does
                    not conform
                  </p>
                </li>
                <li>
                  <h2 id="obs">Your Account</h2>
                  <p>
                    You are responsible for the security of your account, and
                    you will promptly notify Streamfog at support@streamfog.com
                    of any actual or suspected loss, theft, or unauthorized use
                    of your account.
                  </p>
                  <p>
                    You agree not to distribute, license, sell, or transfer your
                    account without our prior written permission.
                  </p>
                  <p>
                    You understand that your content may be transferred
                    unencrypted and may involve (a) transmissions over various
                    networks; and (b) changes to adapt to the technical
                    requirements of connecting networks or devices.
                  </p>
                </li>
              </ul>
            </div>
            <hr className="horizontal_line" />
            <div>
              <h1 id="give" className="highlighted">
                Official Giveaway Rules
              </h1>
              <p>
                NO PURCHASE NECESSARY TO ENTER OR WIN. A PURCHASE WILL NOT
                INCREASE YOUR CHANCES OF WINNING.
              </p>
              <ul style={{ listStyle: "none" }}>
                <li>
                  <h2 id="obs">Sponsor:</h2>
                  <p>
                    This giveaway is sponsored by Streamfog UG, located at
                    5900 Balcones Drive #21582
                    Austin, TX, 78731, USA
                  </p>
                </li>
                <li>
                  <h2 id="obs">Eligibility:</h2>
                  <p>
                    This giveaway is open to legal residents of Germany and
                    other eligible countries who are at least 18 years old at
                    the time of entry. The giveaway is void in North Korea,
                    Iran, Syria, Sudan, and any other countries where giveaways
                    are prohibited by law. Participants must be 18 years of age
                    or older at the time of participation.
                  </p>
                </li>

                <li>
                  <h2 id="obs">Entry Period:</h2>
                  <p>
                    The giveaway begins on <span className="highlighted">27th August ,2024</span> and ends on <span className="highlighted">27th
                      September,2024</span> (the "Entry Period"). Entries submitted
                    before or after the Entry Period will not be eligible.
                  </p>
                </li>
                <li>
                  <h2 id="obs">How to Enter:</h2>
                  <p>
                    To enter, participants must follow the steps outlined on the
                    giveaway page. No purchase is necessary to enter or win. Any
                    attempt to circumvent the entry process by using multiple
                    accounts, fraudulent methods, or other means will result in
                    disqualification.
                  </p>
                </li>
                <li>
                  <h2 id="obs">Prize:</h2>
                  <p>
                    The prize is an Elgato Stream Deck. The prize is
                    non-transferable and cannot be exchanged for cash. Streamfog
                    UG reserves the right to substitute the prize with one of
                    equal or greater value if the original prize becomes
                    unavailable. Please note that Elgato, the brand of the
                    prize, is not affiliated with this giveaway and does not
                    endorse or sponsor it in any way.
                  </p>
                </li>
                <li>
                  <h2 id="obs">Winner Selection and Notification:</h2>
                  <p>
                    The winner will be selected in a random drawing conducted by
                    Streamfog UG or its designated representative on or about
                    <span className="highlighted"> 28th September ,2024</span>. The winner will be notified via email
                    using the contact information provided at the time of entry.
                    The winner has 10 days from the date of notification to
                    respond. If the winner does not respond within this period,
                    or if the prize notification is returned as undeliverable,
                    the winner forfeits the prize, and an alternate winner may
                    be selected.
                  </p>
                </li>
                <li>
                  <h2 id="obs">Prize Delivery:</h2>
                  <p>
                    The prize will be shipped to the winner within 60 days of
                    the winner's confirmation and verification of eligibility.
                    Streamfog UG is not responsible for any delays in prize
                    delivery due to customs or shipping issues.
                  </p>
                </li>
                <li>
                  <h2 id="obs">Taxes:</h2>
                  <p>
                    All taxes, customs duties, and any other expenses not
                    specifically mentioned herein are the sole responsibility of
                    the winner.
                  </p>
                </li>
                <li>
                  <h2 id="obs">Privacy:</h2>
                  <p>
                    By entering this giveaway, participants agree that their
                    personal information may be used by Streamfog UG for
                    marketing, promotional, and other business purposes.
                    Streamfog UG may share participants' information with its
                    affiliates and partners. Please review our{" "}
                    <a href="https://streamfog.com/policy">Privacy Policy</a>
                    for more information on how we collect, use, and share
                    personal information.
                  </p>
                </li>
                <li>
                  <h2 id="obs">Disqualification:</h2>
                  <p>
                    Any attempt to tamper with the entry process or to otherwise
                    defraud Streamfog UG will result in immediate
                    disqualification and may result in legal action.
                  </p>
                </li>
                <li>
                  <h2 id="obs">Limitation of Liability:</h2>
                  <p>
                    By participating, entrants agree to release and hold
                    harmless Streamfog UG, its affiliates, subsidiaries,
                    advertising and promotion agencies, and all of their
                    respective officers, directors, employees, and agents, from
                    any and all liability for any injuries, loss, or damage of
                    any kind arising from or in connection with the giveaway or
                    the receipt, use, or misuse of any prize.
                  </p>
                </li>
                <li>
                  <h2 id="obs">Governing Law:</h2>
                  <p>
                    This giveaway and the rights and obligations of Streamfog UG
                    and participants will be governed by and construed in
                    accordance with the laws of Germany, without giving effect
                    to any choice of law or conflict of law rules. Any disputes
                    arising out of or related to this giveaway shall be resolved
                    individually, without resort to any form of class action,
                    and exclusively by the appropriate court located in Germany.
                  </p>
                </li>
                <li>
                  <h2 id="obs"> General Conditions:</h2>
                  <p>
                    Streamfog UG reserves the right to cancel, suspend, or
                    modify the giveaway if any fraud, technical failures, or any
                    other factor impairs the integrity or proper functioning of
                    the giveaway, as determined by Streamfog UG in its sole
                    discretion. In such event, Streamfog UG reserves the right
                    to award the prize at random from among the eligible entries
                    received up to the time of the impairment.
                  </p>
                </li>
                <li>
                  <h2 id="obs">Trademarks:</h2>
                  <p>
                    All trademarks, service marks, and trade names of Streamfog
                    UG used in the giveaway are the property of Streamfog UG.
                  </p>
                </li>
                <li>
                  <h2 id="obs">Winner Announcement:</h2>
                  <p>
                    The winner will be contacted by email. Once confirmed, the
                    winner’s Twitch username will be publicly announced on
                    Streamfog UG’s social media channels.
                  </p>
                </li>
                <li><p>**By entering, participants agree to be bound by these official rules.**</p></li>
              </ul>
            </div>
            <hr className="horizontal_line" />
            <div>
              <h1 id="behavior" className="highlighted">
                User Behaviour
              </h1>
              <ul style={{ listStyle: "none" }}>
                <li>
                  <h2 id="obs">
                    Reporting a Violation and Potential Consequences
                  </h2>
                  <p>
                    If you come across any user or content that seems to violate
                    our user conduct rules, please report it to us at
                    support@streamfog.com.
                  </p>
                  <p>
                    Depending on the violation, we may take several enforcement
                    actions, such as:
                  </p>
                  <ul>
                    <li>Issuing warnings;</li>
                    <li>Removing content;</li>
                    <li>
                      Suspending or removing the accounts and/or servers
                      responsible;
                    </li>
                    <li>Potentially reporting to law enforcement</li>
                  </ul>
                </li>
                <li>
                  <h2 id="obs">Disallowed Conduct</h2>
                  <p>
                    Our aim is to cultivate a secure, enjoyable, and inclusive
                    environment for all users. We have set rules concerning
                    disallowed conduct to protect our users. Below is a list of
                    conduct that is not allowed.
                  </p>
                </li>
                <li>
                  <h2 id="obs">
                    <i>Respect and Civility</i>
                  </h2>
                  <ul>
                    <li>Do not intimidate, stalk, bully, or harass others.</li>
                    <li>Do not disseminate false or misleading information.</li>
                    <li>
                      Do not post content that breaches our User Content rules.
                    </li>
                    <li>
                      Do not encourage or promote violations of these rules by
                      others.
                    </li>
                    <li>Do not endorse hate or bigotry.</li>
                  </ul>
                </li>
                <li>
                  <h2 id="obs">Safety</h2>
                  <ul>
                    <li>
                      Do not engage in any predatory behaviour with minors,
                      including attempting to manipulate and exploit a child
                      (i.e., grooming), engaging in sexual conversation with or
                      soliciting sexual material from minors.
                    </li>
                    <li>
                      Do not share, request, or discuss imagery related to child
                      sexual exploitation
                    </li>
                    <li>
                      Do not threaten others with real-world harm, including
                      property damage or sexual assault.
                    </li>
                    <li>
                      Do not promote terrorism or political violence, or
                      advocate, recruit, or fundraise for terrorist
                      organizations or organizations that promote political
                      violence.
                    </li>
                    <li>
                      Do not encourage, glorify, or depict suicide. If you're
                      worried about a user's mental health, please contact
                      support@streamfog.com.
                    </li>
                    <li>
                      Do not depict any real-world activity that is dangerous or
                      unsafe.
                    </li>
                    <li>Do not break the law.</li>
                    <li>
                      If reported to us, we may disable the account of any user
                      who:
                      <ul>
                        <li>
                          Harasses, bullies, discriminates or harms others
                          outside of the Platform.
                        </li>
                        <li>
                          {" "}
                          Is affiliated with organized crime, terrorist
                          organizations, or organizations that engage in
                          violence.
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li>
                  <h2 id="obs">Abuse of the Platform</h2>
                  <ul>
                    <li>
                      Do not attempt to access Streamfog systems, data, or user
                      accounts without authorization.
                    </li>
                    <li>
                      Do not attempt to explore, scan, or circumvent any
                      Platform features or security measures on the site.
                    </li>
                    <li>
                      Do not attempt to interfere, disrupt, or overburden any
                      Streamfog or user computer, host, or network including,
                      without limitation, by submitting malicious code,
                      flooding, “mail-bombing,” or “crashing”.
                    </li>
                    <li>
                      Do not use the Site for recording, processing, or mining
                      information about other users.
                    </li>
                    <li>
                      Do not display any unauthorized commercial advertisements.
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <hr className="horizontal_line" />
            <div>
              <h1 id="content" className="highlighted">
                User Content
              </h1>
              <ul style={{ listStyle: "none" }}>
                <li>
                  <h2 id="obs">Definition of User Content</h2>
                  <p>
                    You have the ability to contribute content to our Platform.
                    This is referred to as "User Content". This section details
                    how you maintain ownership of content you own, the limited
                    rights you grant us to utilize the User Content, and our
                    regulations surrounding User Content.
                  </p>
                </li>
                <li>
                  <h2 id="obs">Ownership of User-Generated Content</h2>
                  <p>
                    Users of the Services (be it you or others) may furnish us
                    with content, including but not limited to content offered
                    via livestreaming, text, photos, images, audio, video, code
                    and any other materials ("User Content"). Your User Content
                    remains yours, except for the limited rights that enable us
                    to deliver, enhance, promote and safeguard the Services as
                    outlined in these terms.
                  </p>
                </li>
                <li>
                  <h2 id="obs">Your License to Us</h2>
                  <p>
                    When you submit User Content through the Services, you
                    provide Streamfog (inclusive of our thirdparty hosting
                    providers working on our behalf) a non-exclusive, worldwide,
                    perpetual, irrevocable, royalty-free, transferable,
                    sublicensable right and license to use, host, store,
                    reproduce, modify, create derivative works of (such as those
                    resulting from translations, adaptations or other changes we
                    make so that User Content integrates better with the
                    Services), communicate, publish, publicly display, publicly
                    perform and distribute User Content for the limited purposes
                    of allowing us to deliver, enhance, promote and protect the
                    Services.
                  </p>
                  <p>
                    This Section does not impact any rights you may have under
                    relevant data protection laws.
                  </p>
                </li>
                <li>
                  <h2 id="obs">Use Content Within Your Rights</h2>
                  <p>
                    Please refrain from copying, uploading, downloading or
                    sharing content unless you possess the right to do so.
                  </p>
                  <p>
                    You declare and assure that you own all rights to your User
                    Content or otherwise possess (and will continue to possess)
                    all rights and permissions to legally use, share, display,
                    transfer and license your User Content through the Services
                    and in the manner required by this Agreement. If we use your
                    User Content in the ways delineated in this Agreement, you
                    declare and assure that such use will not infringe or
                    violate the rights of any third party, including but not
                    limited to any copyrights, trademarks, privacy rights,
                    publicity rights, contract rights, trade secrets or any
                    other intellectual property or proprietary rights.
                    Additionally, content on the Services may be protected by
                    others' intellectual property, trade secret or other rights
                  </p>
                </li>
                <li>
                  <h2 id="obs">Content Regulations</h2>
                  <p>The following types of User Content are disallowed:</p>
                  <h3>
                    <i>
                      No content that violates our user conduct rules or other
                      regulations
                    </i>
                  </h3>
                  <ul>
                    <li>
                      {" "}
                      We disallow any content that contravenes our user conduct
                      rules above, or any other part of our terms of service.
                    </li>
                  </ul>
                  <p>
                    This rule encompasses the majority of content issues. Here
                    are specific types of content that we explicitly prohibit:
                  </p>
                  <ul>
                    <li>
                      No fraudulent content
                      <ul>
                        <li>
                          {" "}
                          that you recognize is fraudulent or misleading,
                          libelous, defamatory, obscene,
                        </li>
                      </ul>
                    </li>
                    <li>
                      No content that is offensive, hateful, or illegal
                      <ul>
                        <li>
                          {" "}
                          Content that is pornographic, excessively violent,
                          abusive, or threatening in any way; offensive or
                          promotes or otherwise incites racism, bigotry, hatred
                          or physical harm of any kind against any group or
                          individual;
                        </li>
                        <li>
                          Promotes or encourages actions that could be deemed a
                          criminal offense, or otherwise violate any law
                        </li>
                      </ul>
                    </li>
                    <li>
                      No creation of security/privacy risks to others
                      <ul>
                        <li>
                          Content that contains information that induces or
                          creates a privacy or security risk to any individual;
                        </li>
                      </ul>
                    </li>
                    <li>
                      No content stolen from others or infringes the
                      intellectual property rights of others.
                      <ul>
                        <li>
                          Refer to our “Intellectual Property” section below for
                          more information.
                        </li>
                      </ul>
                    </li>
                    <li>
                      No spamming
                      <ul>
                        <li>
                          Involves the sending of “junk mail”, “chain letters”,
                          or any unsolicited mass mailing, “spimming”, or
                          “spamming”;
                        </li>
                      </ul>
                    </li>
                    <li>
                      No restricted pages
                      <ul>
                        <li>
                          Contains restricted or password-only access pages or
                          hidden pages or images (those not linked to or from
                          another accessible page);
                        </li>
                      </ul>
                    </li>
                    <li>
                      No posting other people without consent
                      <ul>
                        <li>
                          Includes a photograph or video of another individual
                          that you have posted without that person’s consent.
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <hr className="horizontal_line" />
            <div>
              <h1 id="intel" className="highlighted">
                Intellectual Property
              </h1>
              <ul style={{ listStyle: "none" }}>
                <li id="legal">
                  <h2>Compliance with Legal Norms</h2>
                  <p>
                    You commit to comply with laws relating to copyright,
                    trademark, patent, and trade secret ownership and the usage
                    of intellectual property, and you shall be solely
                    accountable for any breaches of any laws and for any
                    infringements of any intellectual property rights instigated
                    by any content you provide, post, or transmit, or that is
                    provided or transmitted using your username or user ID.
                  </p>
                </li>
                <li id="property">
                  <h2>Streamfog's Intellectual Property</h2>
                  <p>
                    All textual content, graphics, user or visual interfaces,
                    trademarks, logos, music, sounds, artwork, photographs,
                    videos, and computer code ("Streamfog Content"), including
                    but not limited to the design, structure, selection,
                    coordination, expression, “look and feel,” and the
                    arrangement of such Streamfog Content, exhibited or
                    performed on the Services is owned, controlled, or licensed
                    by Streamfog.
                  </p>
                  <p>
                    All such Streamfog Content is safeguarded by trade dress,
                    copyright, patent, and trademark laws, and an assortment of
                    other intellectual property rights and unfair competition
                    laws. Unless another agreement applies to particular
                    Streamfog Content, Streamfog grants you restricted
                    permission to use the Streamfog Content subject to these
                    Terms, as long as the use of such Streamfog Content is
                    solely for your personal, informational use.
                  </p>
                  <p>
                    Unless expressly authorized in an applicable agreement or on
                    the Streamfog Content itself, none of the Streamfog Content
                    may be copied, reproduced, distributed, republished,
                    downloaded, displayed, posted, or transmitted in any form or
                    by any means, including but not limited to electronic,
                    mechanical, photocopying, recording, or other means, without
                    the prior explicit written permission of Streamfog and/or
                    the appropriate owner. Furthermore, you may not “mirror” any
                    Streamfog Content contained on this Website or any other
                    server without Streamfog’s express prior written consent.
                  </p>
                </li>
                <li id="open">
                  <h2>Open Source Software</h2>
                  <p>
                    Certain open-source licenses ("Open Source Software") may be
                    incorporated into the Services. The Open Source Software is
                    licensed under the terms of the license that accompanies
                    such Open Source Software. Nothing in these Terms restricts
                    your rights under, or grants you rights that supersede, the
                    terms and conditions of any applicable end-user license for
                    such Open Source Software.
                  </p>
                </li>
                <li id="feedback">
                  <h2>Feedback</h2>
                  <p>
                    Any feedback you provide on this site shall be considered to
                    be non-confidential. Streamfog shall have the freedom to use
                    such information without restrictions.
                  </p>
                  <p>
                    You agree that your remarks will not violate any right of
                    any third-party, including copyright, trademark, privacy,
                    personality, or other personal or proprietary right.
                  </p>
                  <p>
                    You further agree that your remarks will not contain
                    libelous or otherwise unlawful, abusive or obscene material,
                    or contain any computer virus or other malware that could in
                    any way affect the operation of the Service or any related
                    website.
                  </p>
                  <p>
                    You may not use a false e-mail address, pretend to be
                    someone other than yourself, or otherwise mislead us or
                    third-parties as to the origin of any comments. You are
                    solely responsible for any comments you make and their
                    accuracy. We take no responsibility and assume no liability
                    for any comments posted by you or any third-party.
                  </p>
                </li>
                <li id="paid">
                  <h2>Paid Services and Charges</h2>
                  <p>
                    Some of our Services require payment. This section outlines
                    our approach to payments for these services. We
                    automatically bill you at regular intervals (e.g., monthly
                    or annually) for specific paid services like domain
                    registrations and subscriptions unless you cancel the
                    service. Cancellation can be done at any time through our
                    customer support or by emailing support@ourcompany.com.
                  </p>
                </li>
                <li id="reliable">
                  <h2>Reliability of Billing and Account Details</h2>
                  <p>
                    You commit to providing up-to-date, accurate, and complete
                    purchase and account data for all transactions conducted on
                    our Service. You agree to update your account and other
                    details promptly so we can process your transactions and
                    reach out to you as required.
                  </p>
                </li>
                <li id="fees">
                  <h2>Service Fees</h2>
                  <p>
                    Certain sections of the Services can be accessed by paying a
                    fee ("Paid Services"). The effect of Paid Services will
                    persist until they are canceled or terminated as per this
                    Agreement. We'll inform you about the fees for Paid Services
                    before charging you. You may cancel Paid Services at any
                    time via the Services. In case of delay in payment for Paid
                    Services, we retain the right to suspend or cancel your
                    access to the Paid Services. Transaction fees and additional
                    charges may also apply to certain parts of the Services, and
                    we'll inform you about these fees before charging you.
                  </p>
                </li>
                <li id="taxes">
                  <h2>Taxes</h2>
                  <p>
                    All charges are exclusive of any applicable taxes ("Taxes")
                    unless stated otherwise. You are responsible for all
                    applicable Taxes, and we'll charge Taxes in addition to the
                    fees for the Services when we're required to do so. If
                    you're exempt from Taxes, you must provide us with valid tax
                    exemption documents. We have the right to determine the
                    validity of the documentation provided. Tax exemption will
                    only apply from the date we receive such documentation,
                    provided it is valid and applicable. If we're obligated to
                    pay or collect indirect Taxes (like value-added tax or goods
                    and services tax) on the sale to you under your country's
                    laws, you will be liable for payment of any such indirect
                    Taxes.
                  </p>
                </li>
                <li id="auto">
                  <h2>Auto-Renewal of Subscription</h2>
                  <p>
                    To provide continuous service, we'll automatically bill you
                    for certain Paid Services from the date of your first
                    payment and on each subsequent renewal period until
                    cancellation. The renewal period will be equal to your
                    current subscription's renewal period. For instance, if
                    you're on an annual subscription plan, each billable renewal
                    period will be for one year. We’ll automatically charge you
                    the applicable amount using the payment method you have with
                    us. We’ll let you know in advance if you’re purchasing a
                    Paid Service that includes auto-renewal payments.
                  </p>
                  <p>
                    You can disable auto-renewal at any time via the Services or
                    by emailing customer support at support@streamfog.com
                  </p>
                </li>
                <li id="refund">
                  <h2>Refund Policy</h2>
                  <p>
                    Though you can cancel any Paid Services at any time, you
                    qualify for a refund only if:
                  </p>
                  <ol>
                    <li>
                      {" "}
                      you haven't used any Paid Features contained within the
                      Paid Service and
                    </li>
                    <li>
                      {" "}
                      you've requested a refund within the appropriate refund
                      window.
                    </li>
                  </ol>
                  <p>
                    The refund window is within 14 days of purchase for
                    Streamfog Premium, and 7 days of purchase for all other Paid
                    Services. If we find evidence of fraud, refund abuse, or
                    illegal or unethical behavior, we may deny your refund
                    request at our sole discretion.
                  </p>
                </li>
                <li id="changes">
                  <h2>Changes in Fees</h2>
                  <p>
                    We may alter our fees at any time. We’ll provide you with
                    advance notice of these fee changes via the Services. New
                    fees will not apply retroactively. If you disagree with the
                    fee changes, you have the right to reject the change by
                    canceling the applicable Paid Service before your next
                    payment date.
                  </p>
                </li>
                <li id="charge">
                  <h2>Chargebacks</h2>
                  <p>
                    If you engage your bank or credit card company to decline or
                    reverse the charge of any payable fees to us ("Chargeback"),
                    Streamfog may automatically terminate your Account. If you
                    have any concerns about a payment made to us, we strongly
                    recommend reaching out to customer support via the Services
                    or by emailing support@streamfog.com before initiating a
                    Chargeback. We reserve the right to contest any Chargeback.
                  </p>
                </li>
                <li id="payment">
                  <h2>Payment Processing and Credit Card Conditions</h2>
                  <p>
                    Streamfog uses third-party payment processors (each a
                    "Payment Processor") to bill you through a payment account
                    linked to your Account. The processing of payments will be
                    subject to the terms, conditions, and privacy policies of
                    the Payment Processor, in addition to this Agreement. You
                    agree to pay us, through the Payment Processor, all charges
                    at the prices then in effect for any purchase according to
                    the applicable payment terms. You agree to make payment
                    using the payment method you provide with your Account. We
                    reserve the right to correct, or instruct our Payment
                    Processor to correct, any errors or mistakes, even if
                    payment has already been requested or received.
                  </p>
                  <p>
                    Each Payment Processor will process payments according to
                    its own terms of service, and by using such Payment
                    Processor you agree to their terms.
                  </p>
                  <p>
                    In the event a Chargeback is issued, you are immediately
                    responsible for the full amount of the transaction related
                    to the Chargeback, including any associated fees, fines,
                    expenses, or penalties. You agree that any associated fees,
                    fines, or expenses will be deducted from your Streamfog
                    balance.
                  </p>
                  <p>
                    If our payment processors determine you are incurring
                    excessive Chargebacks, Streamfog may impose additional
                    controls and restrictions on your balance.
                  </p>
                </li>
                <li id="third">
                  <h2>Fees for Third-Party Services</h2>
                  <p>
                    Third-party services purchased via Streamfog may be subject
                    to different refund policies determined by those third-party
                    services and may be non-refundable. The purchase terms and
                    conditions for such third-party services may be displayed
                    during the purchase process. It's your responsibility to
                    verify your ability to purchase, cancel, or obtain a refund
                    for a third-party service. Unless otherwise stated in this
                    Agreement, Streamfog doesn’t offer refunds for purchases of
                    third-party services.
                  </p>
                </li>
                <li id="indem">
                  <h2>Indemnity</h2>
                  <p>
                    Should you use our Services either individually or on behalf
                    of a legal entity (see “Acceptance of Terms” for more
                    detail), you agree to the following:
                  </p>
                  <p>
                    You bear responsibility for your actions during the use of
                    our Services, and you pledge to safeguard and indemnify
                    Streamfog UG (haftungsbeschränkt), its parents, partners,
                    affiliates, directors, agents, contractors, service
                    providers, employees, and interns from any third-party
                    claims or demands resulting from: (a) your use or access of
                    our Services, (b) the content you produce, (c) any violation
                    of these terms or associated terms, (d) any infringement of
                    laws or rights of a third party
                  </p>
                  <p>
                    Streamfog retains the right, at our own expense, to
                    undertake the sole defense and management of any issue
                    falling under your indemnification. In such instances, you
                    agree to assist Streamfog in our defense against such
                    claims.
                  </p>
                </li>
                <li id="limitation">
                  <h2>Limitation of Responsibility</h2>
                  <p>
                    Subject to applicable law, under no circumstances should
                    Streamfog or its related entities, including parents,
                    affiliates, subsidiaries, partners, officers, directors,
                    agents, contractors, licensors, service providers,
                    subcontractors, employees, and interns be held responsible
                    for any claim, harm, or any indirect, special, incidental,
                    or consequential damages of any kind. This includes but is
                    not limited to damages for lost profits, lost data, business
                    interruption, or any other commercial damages or losses that
                    occur due to your use or inability to use our Services. This
                    applies regardless of the cause, regardless of whether we
                    have been notified of such damages, and irrespective of the
                    liability theory (contract, tort, or otherwise).
                  </p>
                  <p>
                    Streamfog's total responsibility to you for all damages will
                    not exceed twenty dollars ($20.00), except as required by
                    law in situations involving personal injury.
                  </p>
                  <p>
                    The above limitations will remain in effect even if a
                    proposed remedy does not accomplish its essential purpose.
                  </p>
                </li>
                <li id="dispute">
                  <h2>Dispute Resolution</h2>
                  <p>
                    We encourage you to contact our customer support at
                    support@streamfog.com if you experience any issues. Most
                    problems can be swiftly addressed by reaching out to us.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Terms;

import React, { useState } from "react";
import helmet from "../imgs/medival_helmet.png";
import tiger from "../imgs/tiger.png";
import img1 from "../imgs/img1.webp";
import img2 from "../imgs/img2.webp";
import img3 from "../imgs/custom_colors.PNG"


export const Modal = ({ imgSrc, alt, onClose }) => {
  const handleBackgroundClick = (e) => {
    if (e.target.className === 'modal') {
      onClose();
    }
  };

  return (
    <div className="modal" onClick={handleBackgroundClick}>
      <span className="close_m" onClick={onClose}>
        &times;
      </span>
      <img className="modal-content" src={imgSrc} alt={alt} />
    </div>
  );
};

export const ImageWithModal = ({ imgSrc, alt, width, height }) => {
  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  return (
    <>
      <div className="faqimg-container">
        <img src={imgSrc} alt={alt} width={width} height={height} onClick={toggleModal}/>
        <div className="after" onClick={toggleModal}>
          <svg
            style={{ color: "#7cd959" }}
            className="icon"
            width="60"
            height="60"
            onClick={toggleModal}
            viewBox="-7.68 -7.68 31.36 31.36"
            xmlns="http://www.w3.org/2000/svg"
            fill="#7cd959"
            stroke="#7cd959"
            strokeWidth="0.8"
            transform="rotate(0)"
          >
            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
            <g
              id="SVGRepo_tracerCarrier"
              strokeLinecap="round"
              strokeLinejoin="round"
              stroke="#7cd959"
              strokeWidth="0.44800000000000006"
            >
              {" "}
              <path
                fill="#7cd959"
                fillRule="evenodd"
                d="M580.330152,212.999001 L578.488194,212.999001 C578.212773,212.999001 577.997715,213.222859 577.997715,213.499001 C577.997715,213.767068 578.21731,213.999001 578.488194,213.999001 L581.507237,213.999001 C581.643364,213.999001 581.764746,213.944316 581.852704,213.855661 C581.94109,213.763694 581.997715,213.642369 581.997715,213.508523 L581.997715,210.48948 C581.997715,210.214059 581.773858,209.999001 581.497715,209.999001 C581.229649,209.999001 580.997715,210.218596 580.997715,210.48948 L580.997715,212.252351 L576.217991,207.472627 C576.022487,207.277123 575.712749,207.283968 575.517487,207.47923 C575.327935,207.668781 575.319269,207.988118 575.510884,208.179734 L580.330152,212.999001 Z M567.667563,199 L569.509521,199 C569.784943,199 570,198.776142 570,198.5 C570,198.231934 569.780405,198 569.509521,198 L566.490479,198 C566.354351,198 566.232969,198.054685 566.145011,198.14334 C566.056625,198.235308 566,198.356632 566,198.490479 L566,201.509521 C566,201.784943 566.223858,202 566.5,202 C566.768066,202 567,201.780405 567,201.509521 L567,199.74665 L571.779724,204.526374 C571.975228,204.721878 572.284966,204.715034 572.480228,204.519772 C572.66978,204.33022 572.678447,204.010883 572.486831,203.819268 L567.667563,199 Z"
                transform="translate(-566 -198)"
              ></path>{" "}
            </g>
            <g id="SVGRepo_iconCarrier">
              {" "}
              <path
                fill="#7cd959"
                fillRule="evenodd"
                d="M580.330152,212.999001 L578.488194,212.999001 C578.212773,212.999001 577.997715,213.222859 577.997715,213.499001 C577.997715,213.767068 578.21731,213.999001 578.488194,213.999001 L581.507237,213.999001 C581.643364,213.999001 581.764746,213.944316 581.852704,213.855661 C581.94109,213.763694 581.997715,213.642369 581.997715,213.508523 L581.997715,210.48948 C581.997715,210.214059 581.773858,209.999001 581.497715,209.999001 C581.229649,209.999001 580.997715,210.218596 580.997715,210.48948 L580.997715,212.252351 L576.217991,207.472627 C576.022487,207.277123 575.712749,207.283968 575.517487,207.47923 C575.327935,207.668781 575.319269,207.988118 575.510884,208.179734 L580.330152,212.999001 Z M567.667563,199 L569.509521,199 C569.784943,199 570,198.776142 570,198.5 C570,198.231934 569.780405,198 569.509521,198 L566.490479,198 C566.354351,198 566.232969,198.054685 566.145011,198.14334 C566.056625,198.235308 566,198.356632 566,198.490479 L566,201.509521 C566,201.784943 566.223858,202 566.5,202 C566.768066,202 567,201.780405 567,201.509521 L567,199.74665 L571.779724,204.526374 C571.975228,204.721878 572.284966,204.715034 572.480228,204.519772 C572.66978,204.33022 572.678447,204.010883 572.486831,203.819268 L567.667563,199 Z"
                transform="translate(-566 -198)"
              ></path>{" "}
            </g>
          </svg>
        </div>
      </div>
      {showModal && <Modal imgSrc={imgSrc} alt={alt} onClose={toggleModal} />}
    </>
  );
};

export const faqData = [
  {
    id: "basics",
    title: "Basics",
    questions: [
      {
        question:
          "1. My constraints are not working, on the Streamfog app, why?",
        answer:
          "Do not use constraint modifiers that can only work inside of a Blender environment. It is recommended to use parenting and/or bones to achieve similar results.",
      },
    ],
  },
  {
    id: "keys",
    title: "Shape Keys",
    questions: [
      {
        question: "2. Are shape keys always required?",
        answer: (
          <>
            <p>No, check this:</p>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                gap: "10px",
              }}
            >
              <div>
                <img src={helmet} alt="Medieval helmet" />
                <p>No shape keys</p>
              </div>
              <div>
                <img src={tiger} alt="Tiger" />
                <p>With shape keys</p>
              </div>
            </div>
          </>
        ),
      },
    ],
  },
  {
    id: "export",
    title: "Export",
    questions: [
      {
        question: "3. Can I use Draco compression?",
        answer: "Not yet.",
      },
      {
        question: "4. I have multiple NLA strips, how to export?",
        answer:
          "You must bake your NLA strips into one before exporting the .GLB file.",
      },
    ],
  },
  {
    id: "post",
    title: "Post",
    questions: [
      {
        question: "5. Can I add post processing on Streamfog?",
        answer: "Not yet.",
      },
    ],
  },
  {
    id: "size",
    title: "Size",
    questions: [
      {
        question: "6. What is the ideal size for a glb file?",
        answer: "Ideally, less than 5mb for a smooth experience.",
      },
    ],
  },
  {
    id: "model",
    title: "Model",
    questions: [
      {
        question:
          "7. Why is my model looking different inside of the Streamfog app?",
        answer: (
          <>
            <p>
              Keep your shaders simple. No extra nodes that only Blender
              understands.
            </p>
            <p>Check the following examples:</p>
            <div style={{ display: "flex", gap: "70px" }}>
              <div>
                <ImageWithModal
                  className="img1"
                  imgSrc={img1}
                  alt="Example 1"
                  width={144}
                  height={202}
                />
              </div>
              <div>
                <ImageWithModal
                  className="img2"
                  imgSrc={img2}
                  alt="Example 2"
                  width={176}
                  height={204}
                />
              </div>
            </div>
          </>
        ),
      },
      {
        question:
          "8. How to upload a model with a custom color ?",
        answer: (
          <>
          
            <div style={{ display: "flex", gap: "70px" }}>
              <div>
                <ImageWithModal
                  className="img1"
                  imgSrc={img3}
                  alt="Example 1"
                  width={200}
                  height={117}
                />
              </div>
              
            </div>
          </>
        ),
      },
    ],
  },
];  


import React, { useEffect } from "react";
import Layout from "../Layout";

function Credits() {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="leave-top_margin">
      <Layout>
        <div>
          <div className="streamer_header">
            <h1>
              <b className="highlighted">CREDITS</b>
            </h1>
          </div>
          <div className="streamer_body" style={{ display:"flex", justifyContent:"center"}}>
            <div className="credit-body">
              <h2 className="highlighted"> Streamers credits : </h2>
              <p>
                <b className="highlighted">Triceppps : </b>
                https://www.twitch.tv/triceppps{" "}
              </p>
              <p>
                <b className="highlighted">JoeWo : </b>
                https://www.twitch.tv/joewo{" "}
              </p>
            </div>
            
           
          </div>
        </div>
      </Layout>
    </div>
  );
}

export default Credits;

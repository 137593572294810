import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LandingPage from "./Pages/LandingPage";
import About from "./Pages/About"
import Agencies from "./Pages/Agencies";
import Artists from "./Pages/Artist";
import Credits from "./Pages/Credits";
import Legal from "./Pages/Legal";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import Streamers from "./Pages/Streamers";
import Terms from "./Pages/Terms";
import Blog from './BlogPage/Blog'
import SingleBlog from "./BlogPage/SingleBlog";
import blogs from "./BlogPage/blogs";
import Giveaway from "./Pages/Giveaway"



function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/about" element={<About />} />
        <Route path="/agencies" element={<Agencies />} />
        <Route path="/artists" element={<Artists />} />
        <Route path="/credits" element={<Credits />} />
        <Route path="/legal" element={<Legal />} />
        <Route path="/policy" element={<PrivacyPolicy />} />
        <Route path="/streamers" element={<Streamers />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/giveaway" element={<Giveaway />} />
        <Route path="/blog" element={<Blog blogs={blogs} />} />
        <Route path="/blog/:id" element={<SingleBlog blogs={blogs} />} />
      </Routes>
    </Router>
  );
}

export default App;

import boring_campaigns from "../imgs/boring_campaigns.webm";
import cool_campaigns from "../imgs/cool_campaigns.webm";

import React from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  Grid,
  Chip,
  Box,
  styled,
} from "@mui/material";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import GroupIcon from "@mui/icons-material/Group";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import BrushIcon from "@mui/icons-material/Brush";

// Styled components
const StyledVideo = styled("video")({
  width: "100%",
  height: "auto",
  borderRadius: "8px",
  marginBottom: "16px",
});

const FeatureRow = styled(Box)({
  display: "flex",
  alignItems: "flex-start",
  gap: "12px",
  marginBottom: "16px",
});

const IconWrapper = styled(Box)(({ theme }) => ({
  marginTop: "4px",
}));

const CampaignComparison = () => {
  return (
    <Grid container spacing={1} justifyContent="center">
      {/* Traditional Campaigns Card */}
      <Grid item xs={11} md={6}>
        <Card
          className="campaign_card"
          sx={{
            borderColor: "error.light",
            boxShadow:"rgba(0, 0, 0, 0.35) 0px 5px 15px"
          }}
        >
          <CardHeader
            title={
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="h6" component="div">
                  Traditional Banners
                </Typography>
                <Chip label="Outdated" color="error" size="small" />
              </Box>
            }
            subheader={
              <Typography color="error.main" variant="subtitle2">
                The conventional approach that's falling behind
              </Typography>
            }
          />
          <CardContent>
            <StyledVideo autoPlay loop muted playsInline preload="auto">
              <source src={boring_campaigns} type="video/webm" />
              Your browser does not support the video tag.
            </StyledVideo>

            <FeatureRow>
              <IconWrapper>
                <ThumbDownIcon color="error" />
              </IconWrapper>
              <Typography color="white">
                Blends into the noise of countless similar campaigns
              </Typography>
            </FeatureRow>

            <FeatureRow>
              <IconWrapper>
                <GroupIcon color="error" />
              </IconWrapper>
              <Typography color="white">
                Low viewer engagement and retention rates
              </Typography>
            </FeatureRow>

            <FeatureRow>
              <IconWrapper>
                <BrushIcon color="error" />
              </IconWrapper>
              <Typography color="white">
                Limited creative expression for content creators
              </Typography>
            </FeatureRow>
          </CardContent>
        </Card>
      </Grid>

      {/* AR Campaigns Card */}
      <Grid item xs={11} md={6} alignContent="center">
        <Card
          className="campaign_card"
          sx={{
            borderColor: "success.light",
            boxShadow:"rgba(0, 0, 0, 0.35) 0px 5px 15px"
          }}
        >
          <CardHeader
            title={
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="h6" component="div">
                  Streamfog AR
                </Typography>
                <Chip label="Next Gen" color="success" size="small" />
              </Box>
            }
            subheader={
              <Typography color="success.light" variant="subtitle2">
                Engaging. Immersive. Stunning.
              </Typography>
            }
          />
          <CardContent>
            <StyledVideo autoPlay loop muted playsInline preload="auto">
              <source src={cool_campaigns} type="video/webm" />
              Your browser does not support the video tag.
            </StyledVideo>

            <FeatureRow>
              <IconWrapper>
                <EmojiEventsIcon color="success" />
              </IconWrapper>
              <Typography color="white">
                Stand out in the competitive influencer space with unique AR
                experiences
              </Typography>
            </FeatureRow>

            <FeatureRow>
              <IconWrapper>
                <ThumbUpIcon color="success" />
              </IconWrapper>
              <Typography color="white">
                Creators give positive feedback - streamfog is fun
              </Typography>
            </FeatureRow>

            <FeatureRow>
              <IconWrapper>
                <AutoFixHighIcon color="success" />
              </IconWrapper>
              <Typography color="white">
                Proven track record of enhanced viewer engagement and brand
                recall
              </Typography>
            </FeatureRow>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default CampaignComparison;

import React, { useState, useEffect } from "react";
import Layout from "../Layout";
import { Link } from "react-router-dom";
import CookieConsent from "react-cookie-consent";
import Grid from "@mui/material/Unstable_Grid2";
import hero_bg from "../imgs/bg_round_shapes_new.svg";
import { Marque } from "../Brands/brands";
import CampaignComparison from "../components/CampaignComparison";
import TestimonialRotator from "../components/TestimonialsSlider";

function Modal({ isOpen, onClose, children }) {
  useEffect(() => {
    const body = document.body;
    if (isOpen) {
      body.classList.add("body-no-scroll");
    } else {
      body.classList.remove("body-no-scroll");
    }

    // Clean up function
    return () => {
      body.classList.remove("body-no-scroll");
    };
  }, [isOpen]); // Only re-run the effect if isOpen changes

  if (!isOpen) return null;

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        alignItems: "center", // This helps center vertically
        justifyContent: "center", // This helps center horizontally
        zIndex: 10,
      }}
    >
      <div
        style={{
          width: "80%", // Uses 80% of the viewport width
          height: "80%", // Makes sure it doesn't overflow vertically
          overflowY: "auto", // Adds scroll to the modal content if it overflows vertically
          background: "white",
          padding: "20px",
          boxSizing: "border-box", // Ensures padding is included in the width
          position: "relative", // For the close button positioning
          zIndex: 11,
        }}
        className="modal-content"
      >
        {children}
        <button
          onClick={onClose}
          style={{ position: "absolute", top: 10, right: 10, zIndex: 12 }}
        >
          Close
        </button>
      </div>
    </div>
  );
}

function BookingButton() {
  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
    window.gtag("event", "conversion_event_book_appointment");
  };
  const closeModal = () => setModalOpen(false);

  return (
    <>
      <Link to="#" onClick={openModal}>
        <span className="launch_app">Get a Demo!</span>
      </Link>
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <iframe
          title="Book Appointment"
          src="https://calendar.google.com/calendar/appointments/schedules/AcZssZ1HlsO4YW8y2xPB8kb8XPRNRTz9MneNbsYMHs7ac-2HXYsg-t9q-lnDInbxBcdaFH6HxonS4v4a?gv=true"
          style={{ border: 0 }}
          width="100%"
          height="100%"
        ></iframe>
      </Modal>
    </>
  );
}

function AgencyPage() {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout>
      <section className="agencies">
        <img src={hero_bg} alt="hero-bg" className="background_image" />
        <div className="container-fluid">
          <Grid
            className="desktop-grid"
            container
            spacing={2}
            alignItems={"center"}
            sx={{ position: "relative", zIndex: "6" }}
          >
            <Grid item md={4}>
              <div style={{ flex: 1, padding: 20 }}>
                <h1 className="hero_title" style={{ lineHeight: 1.3 }}>
                  From banners to <span>AR</span> Journeys
                </h1>
                <p className="mt-4">
                  Streamfog provides seamless AR integrations for your
                  campaigns. Our art studio makes your vision into (augmented)
                  reality.
                </p>
                <p>
                  Make your brand stand out, your creators have fun and engage
                  with your audience in a new dimension - literally.
                </p>

                <div
                  style={{
                    display: "flex",
                    marginTop: "40px",
                  }}
                >
                  <BookingButton />
                  <div
                    class="center-container"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      margin: 0 ,
                    }}
                  >
                    <a
                      href="mailto:kev@streamfog.com"
                      style={{ textDecoration: "none" }}
                    >
                      <button style={{ marginLeft: "40px" }}>Contact us</button>
                    </a>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item md={8}>
              <div style={{ flex: 2 }}>
                <CampaignComparison />
              </div>
            </Grid>
          </Grid>
        </div>
      </section>

      <section className="agency_section">
        <Grid container spacing={4} alignItems="center" zIndex={0}>
          {/* Adjust Grid item size based on screen size */}
          <Grid item xs={12} sm={6} md={4}>
            <TestimonialRotator />
          </Grid>
          <Grid item xs={12} sm={6} md={5} className="mobile_brands">
            <h6 className="highlighted">TRUSTED BY :</h6>
            <Marque />
          </Grid>
        </Grid>
      </section>

      <CookieConsent
        enableDeclineButton
        flipButtons
        style={{
          background: "#171d21",
          display: "block",
          textAlign: "center",
          maxWidth: 300,
          padding: "10px 10px 30px",
          boxShadow: "0px 0px 20px rgba(0,0,0,0.2)",
          left: 20,
          marginBottom: 20,
          border: "1px solid #7cd959",
        }}
        buttonStyle={{
          background: "#7cd959",
          fontWeight: 700,
          margin: 0,
        }}
        declineButtonStyle={{ fontWeight: 700, margin: 0, marginLeft: 20 }}
      >
        <h5
          style={{ opacity: 0.9, textTransform: "uppercase", color: "#7cd959" }}
        >
          We use cookies
        </h5>
        <p style={{ opacity: 0.9, marginBottom: 0 }}>
          Cookies help us deliver the best experience on our website. By using
          our website, you agree to the use of cookies.
        </p>
      </CookieConsent>
    </Layout>
  );
}

export default AgencyPage;

import React from "react";
import { useParams } from "react-router-dom";
import Layout from "../Layout";
import { Link } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const SingleBlog = ({ blogs }) => {
  const { id } = useParams();
  const blogId = parseInt(id, 10);
  const blog = blogs.find((blog) => blog.id === blogId);

  if (!blog) {
    return <div>Blog not found</div>;
  }
  return (
    <Layout>
      <Link to={`/blog/`} className="see_all">
        <ArrowBackIosIcon />
        BLOGS
      </Link>

      <div id="top" className="single_blog">
        <h1 className="singleBlog_h">
          <b className="highlighted">{blog.title}</b>
        </h1>
        <p className="singleBlog_dt">{blog.dt}</p>
        <p>
          {" "}
          <span className="badge">{blog.category} </span>
        </p>
        <img src={blog.image} alt="" className="singleblog_image" />
        <p
          className="singleblog_content"
          
        >
          {blog.content}
        </p>
      </div>
    </Layout>
  );
};

export default SingleBlog;

import React, { useEffect, useState } from "react";
import Layout from "../Layout";
import { scrollFunction, scrollToTop, scrollToSection } from "../scroll.js"
import { Link } from "react-router-dom";

function PrivacyPolicy() {
  const [showButton, setShowButton] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      setShowButton(scrollFunction());
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Layout>
      {showButton && (
        <button id="myBtn" onClick={scrollToTop} style={{ display: "block" }}>
          <svg
            style={{ color: "#7cd959" }}
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            fill="currentColor"
            className="bi bi-arrow-up-circle-fill"
            viewBox="0 0 16 16"
          >
            <path
              d="M16 8A8 8 0 1 0 0 8a8 8 0 0 0 16 0zm-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z"
              fill="#4fe83b"
            ></path>
          </svg>
        </button>
      )}

      <div className="streamer_header">
        <h1>
          <b className="highlighted">Streamfog</b> PRIVACY POLICY
        </h1>
        <p>Effective Date: 08/13/2024</p>
      </div>
      <div className="streamer_body">
        <div className="table_contents">
          <h2 className="highlighted">Table of Contents</h2>
          <div className="table-of-contents__item">
            <Link href="#" onClick={() => scrollToSection('intro')}>
              1. Information We Collect
            </Link>
            <ol className="setup_list">
              <li style={{ listStyle: "none" }}>
                <span style={{ fontWeight: "bold" }}> 1.1. </span>
                <Link to="#" onClick={() => scrollToSection("information")}>
                  User-Provided Information
                </Link>
              </li>
              <li style={{ listStyle: "none" }}>
                <span style={{ fontWeight: "bold" }} > 1.2. </span>
                <Link to="#" onClick={() => scrollToSection("automatic")}>
                  Automatically Collected Information
                </Link>
              </li>
            </ol>
          </div>
          <div className="table-of-contents__item">
            <Link href="#" onClick={() => scrollToSection('terms')}>
              2. How We Use Your Information{" "}
            </Link>
          </div>
          <div className="table-of-contents__item">
            <Link href="#" onClick={() => scrollToSection('other')}>
              3. Information Sharing
            </Link>
          </div>
          <div className="table-of-contents__item">
            <Link href="#" onClick={() => scrollToSection('user')}>
              4. Your Choices
            </Link>
          </div>

          <div className="table-of-contents__item">
            <Link href="#" onClick={() => scrollToSection('services')}>
              5. Data Security
            </Link>
          </div>
          <div className="table-of-contents__item">
            <Link href="#" onClick={() => scrollToSection('twitch')}>
              6. Twitch API Usage
            </Link>
          </div>
          <div className="table-of-contents__item">
            <Link href="#" onClick={() => scrollToSection('changes')}>
              7. Changes to This Policy
            </Link>
          </div>
          <div className="table-of-contents__item">
            <Link href="#" onClick={() => scrollToSection('contact')}>
              8. Contact Us
            </Link>
          </div>
        </div>
        <div className="table_body">
          <div className="table_html">
            <div>
              <h2 id="intro" className="highlighted">
                Welcome to Streamfog.
              </h2>
              <p>
                This Privacy Policy explains how we handle your information when you use our website, Twitch extension, and related services (collectively, the "<b className="highlighted">Streamfog Services</b>").
              </p>


            </div>

            <div>
              <h2 id="information" className="highlighted">
                User-Provided Information:
              </h2>
              <ul>
                <li>Account details (e.g., username, email).</li>
                <li>Payment information for purchases.</li>
                <li>Communication data when you contact our support team.</li>

              </ul>
            </div>
            <div>
              <h2 id="automatic" className="highlighted">
                Automatically Collected Information:
              </h2>
              <ul>
                <li>Usage data (e.g., features used, interaction patterns).</li>
                <li>Device information (e.g., IP address, browser type).</li>
                <li>Cookies and similar technologies for service functionality and improvement.</li>

              </ul>
            </div>
            <div>
              <h2 id="terms" className="highlighted">
                We use your information to:
              </h2>
              <ul>
                <li>Provide and maintain the <b className="highlighted">Streamfog Services</b>.</li>
                <li>Process transactions and send related notifications.</li>
                <li>Respond to your requests and inquiries.</li>
                <li>Improve our services and develop new features.</li>
                <li>Ensure the security and proper functioning of our platform.</li>
              </ul>
            </div>
            <div>
              <h2 id="other" className="highlighted">
                We do not sell your personal information. We may share information:
              </h2>
              <ul>
                <li>With service providers who help us operate <b className="highlighted">Streamfog Services</b>.</li>
                <li>To comply with legal obligations.</li>
                <li>To protect our rights, privacy, safety, or property.</li>

              </ul>
            </div>
            <div>
              <h2 id="user" className="highlighted">
                You can:
              </h2>
              <ul>
                <li>Access, update, or delete your account information.</li>
                <li>Opt-out of promotional communications.</li>
                <li>Manage cookie preferences through your browser settings.</li>
              </ul>
            </div>
            <div>
              <h2 id="services" className="highlighted">
                Data Security:
              </h2>
              <p>
                We implement reasonable security measures to protect your information. However, no method of transmission over the Internet is 100% secure.
              </p>

            </div>
            <div>
              <h2 id="twitch" className="highlighted">
                Twitch API Usage:
              </h2>
              <p>
                Our service integrates with the Twitch API. By using <b className="highlighted">Streamfog Services</b>, you also agree to Twitch's Terms of Service and Privacy Policy.
              </p>

            </div>
            <div>
              <h2 id="changes" className="highlighted">
                Changes to This Policy:
              </h2>
              <p>
                We may update this policy periodically. We'll notify you of significant changes via email or through the <b className="highlighted">Streamfog Services</b>.
              </p>

            </div>
            <div>
              <h2 id="contact" className="highlighted">
                Contact Us:
              </h2>
              <p>
                For questions about this policy, please contact <a href="mailto:kev@streamfog.com">us</a>(kev@streamfog.com).
                By using <b className="highlighted">Streamfog Services</b>, you agree to the practices described in this Privacy Policy.
              </p>

            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default PrivacyPolicy;

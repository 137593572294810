import React, {  useEffect } from "react";
import { Typography, Grid, LinearProgress, Box, Divider } from "@mui/material";
import hero_bg from "../imgs/bg_round_shapes_new.svg";
import banner from "../imgs/giveaway-banner.png";
import gift from "../imgs/gift_1.png";
import bolt from "../imgs/bolt.png";
import bolt_1 from "../imgs/bolt_1.png";
import step1 from "../imgs/giveaway-step-1.png";
import step2 from "../imgs/giveaway-step-2.png";
import step3 from "../imgs/giveaway-step-3.png";
import step4 from "../imgs/giveaway-step-4.png";
import step5 from "../imgs/giveaway-step-5.png";
import Layout from "../Layout";
import mobileBanner from "../imgs/giveaway-banner-btn.png"
import stepMobile from "../imgs/mobile_text.png"
import gift2 from "../imgs/gift-2-btn.png";
// import GiveawayReminder from "../Modal/GiveawayReminder";
import { Link } from "react-router-dom";

function Giveaway() {

  // const [isReminderOpen, setIsReminderOpen] = useState(true);

  // const handleReminderClose = () => {
  //   setIsReminderOpen(false);
  // };

  const challenges = [
    {
      icon: step1,
      title: "Retweet",
      description: (
        <>
          Click <span> here </span> and repost our <br /> giveaway post on{" "}
          <span style={{ cursor: "auto" }}> X </span>
        </>
      ),
      progress: 100,
      total: 1,
      completed: true,
    },
    {
      icon: step2,
      title: "Refer Friends",
      description: (
        <>
          Click <span> here </span> to copy your <br /> invite link.
        </>
      ),
      progress: 100,
      total: 5,
      completed: true,
    },
    {
      icon: step3,
      title: "Use Streamfog",
      description: (
        <>
          Click <span> here </span> to setup your <br /> cam and stream for{" "}
          <span style={{ cursor: "auto" }}> 10H </span>
        </>
      ),
      progress: 100,
      total: 10,
      completed: true,
    },
    {
      icon: step4,
      title: "Twitch Extension",
      description: (
        <>
          Get <span> 50 effects </span> triggered <br /> from your viewers
        </>
      ),
      progress: 100,
      total: 50,
      completed: true,
    },
    {
      icon: step5,
      title: "Follow Alert",
      description: (
        <>
          Click <span> here </span> to set follow <br /> alert and get{" "}
          <span style={{ cursor: "auto" }}> 10 followers </span>
        </>
      ),
      progress: 100,
      total: 10,
      completed: true,
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  

  return (
    <Layout>
      <div className="new-hero-section">
        <img src={hero_bg} alt="hero-bg" className="background_image" />
        <Box className="giveaway-wrapper">
          {/* {isReminderOpen && <GiveawayReminder onClose={handleReminderClose} />} */}
          <Grid
            container
            spacing={3}
            className="giveaway-hero-section"
            alignItems="center"
          >
            <Grid item lg={5}>
              <div className="giveaway-text">
                <img src={bolt_1} className="bolt_bg_a" alt="bolt" />
                Stream deck
                <p>
                  Give<span>away</span>
                </p>
              </div>
              <p className="giveaway-tagline">
                Participate in challenges to earn entries for a chance to win
                your
                <span> ELGATO STREAM DECK!</span>
              </p>
              <Link
              className="participate-btn"
              to={"https://app.streamfog.com"}
              target="_blank"
            >
              Participate
            </Link>
            </Grid>
            <Grid item lg={7}>
              <div className="giveaway-banner">
                <img src={banner} alt="banner" />
              </div>
            </Grid>
          </Grid>
          <Typography className="steps-tagline" textAlign="center" mt={0}>
            <span>Increase</span> your chances{" "}
            <span className="big_text"> x5 </span> by completing{" "}
            <span> all challenges!</span>
          </Typography>
          <Box className="giveaway-steps-wrapper">
            <img src={gift} className="gift_bg" alt="gift" />
            <img src={bolt} className="bolt_bg" alt="bolt" />
            <Box className="giveaway-steps" mt={3}>
              <Grid
                className="giveaway-steps_grids"
                container
                wrap="nowrap"
                spacing={2}
              >
                {challenges.map((challenge, index) => (
                  <Grid item xs={4} flexGrow={1} key={index}>
                    <div className="giveaway-step">
                      {index !== 0 && <div className="vertical-line"></div>}
                      <h3>
                        {challenge.title}{" "}
                        <img src={challenge.icon} alt="icon" />
                      </h3>
                      <p>{challenge.description}</p>
                      <div className="giveaway-stats ">
                        <span>
                          {Math.round(
                            (challenge.progress * challenge.total) / 100
                          ) === challenge.total ? (
                            "Done!"
                          ) : (
                            <>
                              {Math.round(
                                (challenge.progress * challenge.total) / 100
                              )}
                              /{challenge.total}
                            </>
                          )}
                        </span>
                        <LinearProgress
                          variant="determinate"
                          value={challenge.progress}
                          sx={{
                            mt: 0.5,
                            backgroundColor: "rgba(0,0,0,0.5)",
                            borderRadius: 50,
                            height: 8,
                            "& .MuiLinearProgress-bar": {
                              backgroundColor: "#7cd959",
                            },
                          }}
                        />
                      </div>
                    </div>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Box>

          <Box
            sx={{
              mt: 6,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
         
          </Box>
          <Divider
            sx={{ borderBottomColor: "rgba(255,255,255,0.2)", mt: 6, mb: 3 }}
          />
          <Box sx={{ mb: 2 }}>
            <Typography sx={{ fontWeight: 700, lineHeight: 1 }}>
              Terms and Conditions :
            </Typography>
            <Typography variant="body2" paragraph color="white" sx={{ my: 1 }}>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://streamfog.com/terms"
                style={{ color: "primary.main", textDecoration: "underline" }}
                className="highlighted"
              >
                View full giveaway terms
              </a>
            </Typography>
            <Typography variant="body2" color="white">
              Key rules - You must be 18+ to enter. Limited to one entry per
              challenge. Open worldwide.
            </Typography>
          </Box>
        </Box>
        <div className="mobile-giveaway">
          <div className="mobile-giveaway-hero">
            <h2 className="giveaway-btn-text">GIVEAWAY</h2>

            <img src={mobileBanner} alt="Dark Keypad" className="dark-keypad-mobile" />

            <img
              src={gift}
              alt="Gift Icon 1"
              className="gift-icon gift-icon-1"
            />
            <img
              src={gift2}
              alt="Gift Icon 2"
              className="gift-icon gift-icon-2"
            />
          </div>
          <p className="mobile-giveaway-tagline">
          <span className="highlighted"> Participate in challenges</span> to earn entries for a chance to win your
            <span className="highlighted"> ELGATO STREAM DECK!</span>
          </p>
          <img src={bolt_1} className="mobile-bolt" alt="bolt" />
          <img
              src={gift}
              alt="Gift Icon 1"
              className="mobile-gift-1"
            />
            <img
              src={gift2}
              alt="Gift Icon 2"
              className="mobile-gift-2"
            />
          <div className="mobile-giveaway-steps">
          
            <img src={stepMobile} alt="Steps" className="mobile-giveaway-banner"/>
            <Link
              className="participate-btn"
              to={"https://app.streamfog.com"}
              target="_blank"
            >
              Participate
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Giveaway;
